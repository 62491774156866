import styles from "../index.module.less";
import {Tabs} from "antd";
import {useState} from "react";
import CreateInput, {ExtInput} from "./CreateInput";
import CreateDraw from "./CreateDraw";
import CreateUpload, {ExtUpload} from "./CreateUpload";
import CreatePaste, {ExtPaste} from "./CeatePaste";
import CreateMobileDraw from "./CreateMobileDraw";
import { useTranslation } from "react-i18next";

export default function CreateTabs(props) {
    const { t } = useTranslation("sealPlugin");
    const [tabIndex, setTabIndex] = useState(4);
    const [inputValue, setInputValue] = useState("");
    const [inputFont, setInputFont] = useState("SimHei,STHeiti,Noto Serif Hebrew");
    const [drawImg, setDrawImg] = useState("");
    const [qrImg, setQrImg] = useState("");
    const [uploadImg, setUploadImg] = useState("");
    const [uploadClear, setUploadClear] = useState(0);
    const [pasteImg, setPasteImg] = useState("");
    const [pasteClear, setPasteClear] = useState(0);

    const tabChange = (key) => {
        setTabIndex(key);
        switch (key) {
            case 0:
                if (inputValue.length < 1) {
                    props.setImgData('')
                    break;
                }
                drawDomTextToCanvas({text: inputValue, fontType: inputFont}).then(data => {
                    props.setImgData(data.img, true, 0, 0.25);
                    return data.fontSize;
                });
                break;
            case 1:
                props.setImgData(drawImg, true, 0, 0.25);
                break;
            case 2:
                props.setImgData(uploadImg, false);
                break;
            case 3:
                props.setImgData(pasteImg, false);
                break;
            case 4:
                props.setImgData(qrImg, true, 0, 0.25);
                break;
            default:
                break;
        }
    }

    const setValue = (value) => {
        if (tabIndex !== 0) {
            return;
        }
        setInputValue(value)
        if (value.length < 1) {
            props.setImgData('')
            return Promise.resolve();
        }
        return drawDomTextToCanvas({text: value, fontType: inputFont}).then(data => {
            props.setImgData(data.img, true, 0, 0.25);
            return data.fontSize;
        });
    }

    const setDraw = (img) => {
        if (tabIndex !== 1) {
            return;
        }
        setDrawImg(img)
        props.setImgData(img, true, 0, 0.25)
    }

    const setUpload = (img) => {
        if (tabIndex !== 2) {
            return;
        }
        setUploadImg(img)
        props.setImgData(img, false)
    }

    const setPaste = (img) => {
        if (tabIndex !== 3) {
            return;
        }
        setPasteImg(img)
        props.setImgData(img, false)
    }

    const setMobileDrawImg = (img) => {
        if (tabIndex !== 4) {
            return;
        }
        setQrImg(img)
        props.setImgData(img, true, 0, 0.25)
    }

    const setFont = (font) => {
        if (tabIndex !== 0) {
            return;
        }
        setInputFont(font)
        drawDomTextToCanvas({text: inputValue, fontType: font}).then(data => {
            props.setImgData(data.img, true, 0, 0.25);
            return data.fontSize;
        });
    }

    const tabExts = [
        <ExtInput setFont={setFont} value={inputValue}  />,
        undefined,
        <ExtUpload setUploadClear={setUploadClear} hasImg={uploadImg} />,
        <ExtPaste setPasteClear={setPasteClear} hasImg={pasteImg} />,
    ];

    const items = [
        { label: t('tab.handDraw'), key: 4, children: <CreateMobileDraw setDrawImg={setMobileDrawImg} />},
        { label: t('tab.input'), key: 0, children: <CreateInput setValue={setValue} font={inputFont} />  },
        { label: t('tab.draw'), key: 1, children: <CreateDraw setDrawImg={setDraw} /> },
        { label: t('tab.import'), key: 2, children: <CreateUpload setUpload={setUpload} uploadClear={uploadClear}  />},
        { label: t('tab.paste'), key: 3, children: <CreatePaste setPasteImg={setPaste} pasteClear={pasteClear}  />}
    ];

    return (
        <div className={styles.tabs} style={{display: !props.visible ? 'none' : ''}}>
            <div style={{color: '#999', marginTop: '0px', fontSize: '14px'}}> {props.isOutTemp ? t('message.tempSealTip'): t('message.createInnerSeal')}</div>
            <Tabs defaultActiveKey={tabIndex}
                  onChange={tabChange} tabBarExtraContent={tabExts[tabIndex]} items={items} >
            </Tabs>
        </div>
    )
}

export function drawDomTextToCanvas ({text, fontType,
                                  fontSize = 100, color = '#000', width = 940, height = 260}) {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext("2d");
    ctx.globalAlpha = 1;
    ctx.textAlign = 'center' //文字居中
    ctx.textBaseline = 'middle' //文字居中
    ctx.fillStyle = color;
    ctx.font=`${fontSize}px  ${fontType}`;
    return new Promise((resolve) => {
        setTimeout(() => {
            let metrics = ctx.measureText(text);
            let len = Math.ceil(metrics.actualBoundingBoxLeft + metrics.actualBoundingBoxRight);
            while (len > width) {
                fontSize -= 10;
                ctx.font=`${fontSize}px  ${fontType}`;
                metrics = ctx.measureText(text);
                len = Math.ceil(metrics.actualBoundingBoxLeft + metrics.actualBoundingBoxRight);
            }
            ctx.fillText(text, width/2, height/2);  // 绘制文本
            resolve({img: canvas.toDataURL('image/png'), fontSize});
        }, 100); // 因特殊字体的加载需要时间，这儿做一个延时处理
    })
}
