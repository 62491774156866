import React from 'react';
import ReactDOM from 'react-dom/client';
import { ConfigProvider } from 'antd';

import './index.css';
import App from './App';
import {getQueryVariable} from "./pages/PublicPage/service";
import {initErrorMonitorTool} from "./utils/env";
import './locales/i18n';

const inOnlineContainer = getQueryVariable('origin') === "ONLINE_CONTAINER"
const isDark = getQueryVariable('theme') === 'dark';
ConfigProvider.config({
    theme: {
        primaryColor: inOnlineContainer ? '#e48bea' : isDark ? '#D4A1E5': '#A236B2',
        infoColor: inOnlineContainer ? '#e48bea' : '#A236B2'
    }
});

initErrorMonitorTool();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ConfigProvider>
        <App />
    </ConfigProvider>
    // <React.StrictMode>
    //     <App />
    // </React.StrictMode>
);
