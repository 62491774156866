import styles from "../index.module.less";
import React, {useRef, useState} from "react";
import {Button, Tooltip, Input} from "antd";
import {errorMessage} from "../../components/Message";
import { useTranslation } from 'react-i18next';
const { TextArea } = Input;
export default function PcViewer(props) {
    const {isOut, companyName, processId} = props.querys;
    const {enterpriseId, token, email, submitFn} = props;
    const [success, setSuccess] = useState(false);
    const [inputStatus, setInputStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const inputRef = useRef();
    const { t } = useTranslation('authPlugin');

    const changeValue = (e) => {
        const value = e.target.value;
        if (!value || value.length > 50) {
            setInputStatus('warning');
        } else {
            setInputStatus('');
        }
    };

    const submit = () => {
        const comment = inputRef.current.resizableTextArea.textArea.value;
        setInputStatus('');
        setLoading(true);
        submitFn({isOut, enterpriseId, comment, token, processId}).then(code => {
            switch (code) {
                case -1:
                    setInputStatus('error');
                    break;
                case -2:
                    errorMessage(t('joinOrg.pc.error.joinOrgFailed'));
                    break;
                default:
                    setSuccess(true);
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    let showOrgName = companyName;
    if (showOrgName.length > 18) {
        showOrgName = showOrgName.substring(0, 15) + '…' + showOrgName.substring(showOrgName.length-2);
    }

    const title = success ? t('joinOrg.pc.title.success') : t('joinOrg.pc.title.joinOrg');
    const summary = success ? t('joinOrg.pc.messages.submitSuccess') : '';

    return (
        <>
            <div className={styles.result}>
                <div className={styles.icon}>
                    <i className={styles.img + ' ' + styles.org}></i>
                    <p className={styles.stat + ' ' + (success ? styles.statOk : '')}>
                        <i className={styles.img + ' ' + (success ? styles.ok : styles.plus)}></i></p>
                </div>

                <div className={styles.container}>
                    <div className={styles.title}>{title}</div>
                    {success && <div className={styles.tips} dangerouslySetInnerHTML={{__html: summary}}></div>}
                    {
                        !success && <>
                            <div className={styles.tips}>
                                <p>{t('joinOrg.pc.label.orgName')}：
                                    <Tooltip title={companyName}>
                                        {showOrgName}
                                    </Tooltip>
                                </p>
                                <p>{t('joinOrg.pc.label.applicant')}：{email}</p>
                            </div>
                            <TextArea placeholder={t('joinOrg.pc.placeholder.reason')} autoSize={{ minRows: 4, maxRows: 4 }}
                                      ref={inputRef} allowClear showCount status={inputStatus} maxLength={50} onChange={changeValue} />
                            {inputStatus === 'error' && <span className={styles.error}>{t('joinOrg.pc.error.reasonRequired')}</span>}
                        </>
                    }
                </div>
            </div>
            <div className={styles.buttons}>
                {!success && <Button loading={loading} onClick={submit} type='primary'
                        style={{width: '90px'}}>{t('joinOrg.pc.common.confirm')}</Button>}
                <Button key="back" onClick={() => props.sendMsg({type:'close'})}
                        style={{width: '90px', marginLeft: '10px'}}>
                    {success ? t('joinOrg.pc.common.close') : t('joinOrg.pc.common.cancel')}</Button>
            </div>
        </>
    );
}

