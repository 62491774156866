import styles from "./Result.module.less";
import icon from "../../../../assets/images/auth-ok.png";
import {Button} from "antd";
import {useState, useEffect} from "react";
import {
    bindAuthAccountForPhantom,
    getAuthResult,
    getEsignConfig,
    getSubscription
} from "../../../../request/esign";
import {getStore} from "../../../../store";
import {closeESignCNDialogAPI, refreshWebForPhantom, updateUserInfoForPhantom} from "../../../../request/phantom";
import {binding} from "../../../../request/esign";
import {errorMessage, loadingMessage, successMessage} from "../../../components/Message";
import {getQueryVariable} from "../../service";
import {useTranslation} from "react-i18next";
// import {useNavigate} from "react-router-dom";

export default function Result(props) {
    const [info, setInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [bindingInfo, setBindingInfo] = useState({});
    //const navigate = useNavigate();

    const { t } = useTranslation('certPlugin');


    useEffect(() => {
        const userInfo = getStore('userInfo');
        const {flowId} = props.resultParams;
        const authParams = props.authParams;
        // const tenantId = userInfo.enterpriseId;
        const tenantId = ''
        const email = userInfo.userEmail;
        const mobile = userInfo.userMobile;
        let isTrial = 1;
        const bind = () => {
            const {orgName, redirectUrl} = authParams;
            if (orgName) {
                if (tenantId ) {
                    return getSubscription({tenantId}).then(res => {
                        const {tenantName} = res;
                        if (tenantName !== orgName) {
                            return Promise.reject('tenantName un-equals orgName: ');
                        } else {
                            return result({orgName});
                        }
                    })
                } else {
                    return result({orgName});
                }
            } else {
                return getSubscription({}).then(res => {
                    isTrial = res.isTrial;
                }).catch(e => {
                    console.error("🍭  ~ file:Result method: line:41 -----", e);
                }).finally(() => {
                    return result({email, mobile});
                })
            }

            function result(params) {
                return getAuthResult(params).then(res => {
                    const data = {
                        authParams,
                        redirectUri: redirectUrl,
                        wardType: 0, // 1：对内；0：对外；目前该saas分支只是针对对外的，所以这里写死为0；
                    };
                    if (email) {
                        data.email = email;
                    }
                    if (mobile) {
                        data.mobile = mobile;
                    }

                    const { accountId, orgId, name, psnSmFlowId, orgSmFlowId } = res;
                    if (orgName) {
                        if (!name || !orgSmFlowId) {
                            return Promise.reject('getAuthResult is error: ' + res);
                        }
                        data.tenantId = tenantId;
                        data.flowId = orgSmFlowId;
                        data.orgId = orgId;
                        data.orgName = name;
                    } else {
                        if (!accountId || !name || !psnSmFlowId) {
                            return Promise.reject('getAuthResult is error: ' + res);
                        }
                        data.accountId = accountId;
                        data.flowId = psnSmFlowId;

                    }
                    data.name = name;
                    return bindAuthAccountForPhantom(data)
                    .then( () => {
                        return pollingSearchBindingResult(name);
                    })
                });
            }
        }
        function pollingSearchBindingResult (name) {
            return binding(getStore('userInfo').token, undefined, name).then(res => {
                if (res.createOrgCode + '' === '0') {
                    successMessage(t('result.companyCreated'))
                    setBindingInfo(res);
                    return res;
                } else if (res.createOrgCode + '' === '610190') {
                    setHasError(true);
                    loadingMessage(t('result.companyCreating'))
                    return pollingSearchBindingResult(name);
                } else if (res.createOrgCode + '' === '610191') {
                    setHasError(true);
                    errorMessage(t('result.companyExistsMultiple'))
                    return Promise.reject(res);
                } else if (res.createOrgCode + '' === '610192') {
                    setHasError(true);
                    errorMessage(t('result.companyCreateFailed'))
                    return Promise.reject(res);
                } else {
                    setHasError(true);
                    console.error(res)
                    return Promise.reject(res);
                }
            })
        }
        const init = () => {
            setLoading(true);
            setHasError(false);
            let limits;
            return getEsignConfig().then(res => {
                limits = res.trial;
                return bind();
            }).then(() => {
                let title,summary;
                const {orgNum, orgPeriod, personNum, personPeriod} = limits;
                const {orgName} = authParams;
                if (orgName) {
                    if (flowId) {
                        title = t('result.companyCertified');
                        summary = t('result.companyCertifiedMessage', { orgNum, orgPeriod })
                    } else {
                        title = t('result.companyAuthorized');
                        summary = '';
                    }
                } else {
                    if (flowId) {
                        title = t('result.individualCertified');
                        summary = isTrial === 0 ? '' : t('result.individualCertifiedMessage', { personNum, personPeriod });
                    } else {
                        title = t('result.individualAuthorized');
                        summary = '';
                    }
                }
                setInfo({title, summary});
            }).catch(e => {
                console.error('getAuthResult error', e);
                setHasError(true);
            })
            .finally(() => {
                setLoading(false);
            })
        }
        init();
    }, [props]);


    const handleClick = () => {
        //navigate('/main');
        if (getStore('plugin').inPhantom) {
            if (bindingInfo && bindingInfo.orgList && bindingInfo.orgList[0] && bindingInfo.orgList[0].tenantId) {
                updateUserInfoForPhantom({ enterpriseId: bindingInfo.orgList[0].tenantId })
                .then(() => {
                    const from = getQueryVariable('from');
                    const type = from === 'signFlowPanel' ? 2 : 3;
                    return refreshWebForPhantom({ type })
                })
                .then(() => {
                    const forJoinOrg = !!getQueryVariable('forJoinOrg');
                    const closeParent = forJoinOrg ? 1 : 0;
                    closeESignCNDialogAPI(closeParent);
                })

            } else {
                console.log('invalid bindingInfo: ', bindingInfo);
            }
        } else {
            window.location.href = '/'
        }
    }

    return (
        <div className={styles.main}>
            {!loading && !hasError && info.title && <>
                    <img src={icon} alt={"icon"} width="355" />
                    <div className={styles.title}>{info.title}</div>
                    <div className={styles.tips}>
                        {info.summary}
                    </div>
                    <Button onClick={()=> handleClick()} shape={"round"} type={"primary"} style={{width: '60%'}}>{t('result.startSigning')}</Button>
                </>
            }
            {
                loading && !hasError &&
                <div className={styles.loading}>
                    <div className={styles.loadingBg}>
                        <div className={styles.loadingIcon}></div>
                    </div>
                    <div className={styles.loadingText}>{t('result.checkingCompanyCreation')}</div>
                </div>
            }
            {
                hasError &&
                <div className={styles.hasError}>
                    <div className={styles.errorIcon}></div>
                    <div className={styles.hasErrorText}>{t('result.companyCreationCheckFailed')}</div>
                </div>
            }
        </div>
    )
}
