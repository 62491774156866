import {useEffect} from "react";
import {getQueryVariable} from "../pages/PublicPage/service";
import i18n from "../locales/i18n";

export default function useLocal() {
    useEffect(() => {
        let lang = getQueryVariable('lang');
        if(lang === 'en') lang = 'en-US';
        if(!['en-US', 'zh-CN'].includes(lang)) {
            lang = 'zh-CN';
        }
        i18n.changeLanguage(lang).catch(e => {
            console.log('change language error:', e)
        })
    }, [])
};