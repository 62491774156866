import axios from "axios";
import {getPhantomVersion, inPublicPageEnv} from "../pages/PublicPage/utils/util";
import {errorMessage} from "../pages/components/Message";
import {
    ERROR_AC_FAIL,
    ERROR_AC_TOKEN_FAIL,
    ERROR_RES_CODE
} from "./error_container";
import {loginByPublicPageEnv} from "../pages/PublicPage/Model";
import {doLogout} from "../utils";
import OtelService from "../utils/otel/service";
import {getStore} from "../store";

const request = axios.create({
    timeout: 5000,
});

const otelService = new OtelService();

const CLIENT_TYPE = {
    WIN_EDITOR: 'win-editor',
}

function _getToken() {
    const userInfo = getStore('userInfo') || {};
    return userInfo?.token || '';
}

function _getUrl() {
    const configUrls = getStore('configUrls') || {};
    const url = localStorage.getItem('commonServiceCNUrl');
    if (url) {
        return url;
    }
    return configUrls['commonServiceCNUrl'];
}

request.interceptors.request.use(
    config => {
        const baseUrl = _getUrl();
        const url = config.url;
        if (url.includes(baseUrl)) {
            // let clientFrom = XClientFrom;
            // if (!clientFrom) {
            //     if (inPhantom()) {
            //         clientFrom = CLIENT_TYPE.WIN_EDITOR;
            //     }
            // }
            const traceHeaders = otelService.getTraceHeaders();
            config.headers = {...config.headers, ...traceHeaders};
        }
        return config;
    }, function (error) {
        // 对请求错误做些什么
        return _handleNetworkError(error, '网络连接请求错误');
    });

request.interceptors.response.use(
    response => {
        // httpResponseHandle(response);
        return _handleResponse(response);
    },  error => {
        return _handleNetworkError(error, '网络连接响应错误');
    }
);

function _handleNetworkError(error, title) {

    if (title) {
        errorMessage(title);
    }
    return Promise.reject(error);
}


function _handleResponse(res) {
    const result = res.data;
    if (result) {
        const code = result.code === undefined ? result.ret : result.code;
        const message = result.msg === undefined ? result.message : result.msg;
        if (code === 0 ) {
            return result.data;
        } else {
            return _handleResError(Number.isInteger(code) ? code : -2, message, res);
        }
    } else {
        return _handleResError(-1, '', res);
    }
}

function _handleResError(code, message) {
    let title, errorKey;
    if (code < 0) {
        title = '网络数据响应错误';
    } else {
        for (let key in ERROR_RES_CODE) {
            if (ERROR_RES_CODE[key] === code || (Array.isArray(ERROR_RES_CODE[key]) && ERROR_RES_CODE[key].includes(code))) {
                if (ERROR_AC_TOKEN_FAIL === key) { // ac token error
                    title = '登录过期或状态错误，请重新登录';
                    code = undefined;
                    message = undefined;
                    if(inPublicPageEnv()) {
                        loginByPublicPageEnv()
                    } else {
                        setTimeout(() => {doLogout(_getToken())}, 2000); // 延时可查看提示
                    }
                } else if (key !== ERROR_AC_FAIL) {
                    errorKey =  key;
                }
                break;
            }
        }

        if (!errorKey && !title) {
            title = '未知的网络数据错误';
        }
    }

    if (title) {
        if (code) {
            title += `: ${code}`;
        }
        if(message) {
            title += `(${message})`;
        }
    }

    return _handleNetworkError(errorKey, title);
}

export async function createNps({ score, feedback, org_id, org_name, ward_type }) {
    const ver = getPhantomVersion();
    const data = {
        access_token: _getToken(),
        score,
        feedback: feedback ?? '',
        project: 'esign',
        org_id: org_id ?? '',
        org_name: org_name ?? '',
        ext: ward_type,
        ext2: ver ? ver.join('.') : ''
    };
    const params = { client: CLIENT_TYPE.WIN_EDITOR };
    const url = _getUrl() + '/api/nps/create';
    return request.post(url, data, { params});
}