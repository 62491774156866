/**
 * 公共插件，用于管理印章
 * 插件通过iframe的方式嵌入到页面中，通过query params向插件发送消息，通过postMessage返回结果
 * 操作完成后，默认不会返回操作结果是否成功失败状态（因为第三方状态不可知），建议在返回后调用方主动请求印章列表接口，刷新印章列表

 * 请求路径 pcDomain + /sealPlugin

 * 传入参数
 * 登录令牌 token
 * 调用方：client 0/1/2 0默认：pc端 1：移动端 2：客户端
 * 章类型：isOut 0/1 0默认：对内 1：对外
 * 业务类型：type 0/1 0默认：列表 1：添加，2：移动端手绘签名
 * 对外列表类型 可选：listType 1 仅对外个人章列表，2：仅对外企业章列表
 * 组织id 可选，企业章必传：enterpriseId 对内或对外企业必传
 * 实名帐号 可选，对外章必传：accountId, 对外章必传
 * 实名企业帐号 可选，对外企业章必传：orgId，对外企业章必传
 * 真实姓名 可选，对外企业章必传：trueName 对外章必传
 * 是否临时章 可选：isTemp 0/1 0默认：否 1：是
 * 是否是来自管理功能的入口 可选：isAdmin 0/1 0默认：否 1：是
 * 语言 lang zh-CN/en-US，默认为zh-CN
 * 主题 theme light/dark，默认为light
 *

 * 传入可选参数
 * 自定义header中x-client-from字段，用于区分不同的调用方 cf, clientFrom 如不传则通过client值判断
 * 调用方自传字段，会原样返回 from


 * 返回的消息结构体
 * type: close/resize
 * reAuth 当请求企业章列表时，如果当前的状态非企业或企业未实名，则会返回reAuth=true，用于提示调用方重新实名认证
 */
import React, {useEffect} from 'react';
import {setHeaderClient} from "../../request/esign";
import {getParams} from "../../utils";
import InnerList from "./InnerList";
import CreateSeal from "./createInnerSeals/CreateSeal";
import OuterList from "./OuterList";
import ChooseType from "./ChooseType";
import OutPanel from "./OutPanel";
import CreateDraw4Mobile from "./createInnerSeals/CreateDraw4Mobile";
export const SEAL_PLUGIN_URI = `/sealPlugin`;
const MsgName = 'esignCNSealPlugin';

export default function SealPlugin() {
    const [type, setType] = React.useState(-1);
    const [listType, setListType] = React.useState(-1);
    const [isOut, setIsOut] = React.useState(true);
    const [isTemp, setIsTemp] = React.useState(false);
    const [isAdmin, setIsAdmin] = React.useState(false);

    const queryParams = getParams() || {};
    const {token, accountId, orgId, enterpriseId, trueName} = queryParams;

    useEffect(() => {
        // sendMsg({type: 'resize', width: '640px', height: '320px'});
        const queryParams = getParams() || {};
        const {token, type, isOut, clientFrom, client, listType, isTemp, isAdmin, lang, theme} = queryParams;
        if (!token) {
            return;
        }

        if (theme === 'dark') {
            document.documentElement.setAttribute('data-theme', 'dark');
        }

        setHeaderClient({client, value: clientFrom});
        setType(parseInt(type));
        setListType(parseInt(listType) || -1);
        setIsOut(parseInt(isOut) === 1);
        setIsTemp(parseInt(isTemp) === 1);
        setIsAdmin(parseInt(isAdmin) === 1);
        return () => {

        }
    }, []);

    const close = (seals, reAuth = false, width = 0, height = 0) => {
        sendMsg({
            type: 'close',
            success: !!seals,
            sealData: seals,
            width, // mm
            height, // mm
            reAuth
        });
    }
    return (
        <div>
            {type === 0 && listType < 1 && isOut && <OuterList close={close} />}
            {type === 1 && isOut && !isTemp && <ChooseType close={close} />}
            {type === 0 && listType === 1 && isOut && <OutPanel accountId={accountId} token={token} />}
            {type === 0 && listType === 2 && isOut && <OutPanel isAdmin={isAdmin} trueName={trueName} accountId={accountId} enterpriseId={enterpriseId} orgId={orgId} token={token} />}
            {type === 0 && !isOut && <InnerList  />}
            {type === 1 && (!isOut || (isOut && isTemp)) && <CreateSeal close={close} ></CreateSeal>}
            {type === 2 && <CreateDraw4Mobile />}
        </div>
    );
}

export function sendMsg(data = {}) {
    if (!data) {
        return;
    }
    if (!data.type) {
        return;
    }
    if (!['close', 'resize'].includes(data.type)) {
        return;
    }
    data.name = MsgName;
    const queryParams = getParams() || {};
    data.from = queryParams.from;
    window.parent.postMessage(data, '*');
}