import React, {Component} from 'react';
import styles from './index.module.less';
import {Button, Modal, Spin, Tooltip} from 'antd';
import {errorMessage, loadingMessage, successMessage} from "../components/Message";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {applyOutStampUsePermission, getOrgSeals, resendApplyOutStampUsePermission} from "../../request/esign";
import {sendMsg} from "./index";
import { withTranslation } from 'react-i18next';

class ApplyOrgSeals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stampList: [],
            loading: false,
            applyLoading: false,
        };
        const {token, accountId, orgId, trueName, enterpriseId} = props;
        this.token = token;
        this.accountId = accountId;
        this.orgId = orgId;
        this.trueName = trueName;
        this.enterpriseId = enterpriseId;
    }

    async handleApplyStamp(sealInfo) {
        const { t } = this.props;
        const data = {
            orgId: this.orgId,
            accountId: this.accountId,
            sealId: sealInfo.sealId,
            token: this.token,
        };
        const isResend = +sealInfo.authStatus === 1;
        let request;
        if (!isResend) {
            data.sealName = sealInfo.alias;
            data.applicantName = this.trueName;
            request = applyOutStampUsePermission;
        } else {
            request = resendApplyOutStampUsePermission;
        }
        Modal.confirm({
            title: t('title.sealApplication'),
            icon: <ExclamationCircleOutlined/>,
            content: isResend ? t('message.resendConfirm') : t('message.applyConfirm'),
            okText: t('button.confirm'),
            cancelText: t('button.cancel'),
            okButtonProps: {},
            cancelButtonProps: {},
            onOk: () => {
                const name = isResend ? t('button.urge') : t('button.apply');
                this.setState ({applyLoading: true});
                loadingMessage(name + t('message.submitting'));
                request(data).then(() => {
                    successMessage(name + t('message.submitSuccess'));
                    !isResend && this.getOrgSeals();
                }).catch(() => {
                    errorMessage(name + t('message.submitFailed'));
                }).finally(() => {
                    this.setState({applyLoading: false});
                });
            },
            onCancel() {}
        });
    }

    componentDidMount() {
        sendMsg({type: 'resize', width: '890px', height: '606px'});
        this.getOrgSeals();
    }

    getOrgSeals() {
        this.setState({loading: true});
        getOrgSeals({orgId:this.orgId, accountId:this.accountId, token:this.token, enterpriseId:this.enterpriseId, valid: false}).then(seals => {
            if (Array.isArray(seals) && seals.length > 0) {
                this.setState({stampList: seals});
                return;
            }
            this.setState({stampList: []});
        }).catch(() => {
            // errorMessage('获取印章失败，请关闭后重试');
        }).finally(() => {
            this.setState({loading: false});
        });
    }

    render() {
        const { t } = this.props;
        const {
            stampList,
            loading,
            applyLoading
        } = this.state;

        return (
            <div className={styles.applyDialog}>
                {loading && <Spin className={styles["spin-loading"]}></Spin>}
                <section className={styles["stamp-apply-container"]}>

                    {!loading && stampList && stampList.length > 0 && <>
                        <div className={styles["header"]}>
                            <div className={styles["left"]}>
                                <span>{t('tips.selectSealToApply')}</span>
                            </div>
                        </div>
                        <div className={styles.list}>
                            {
                                stampList.map(item => {
                                    let stampTag, stampClass;
                                    const {usePermission, authStatus, sealId, alias, base64} = item;
                                    if (+authStatus === 1) {
                                        stampTag = t('status.inReview')
                                        stampClass = styles['stamp-tag']
                                    } else if (usePermission) {
                                        stampTag = t('status.authorized')
                                        stampClass = styles['stamp-tag-success']
                                    }
                                    return (
                                        <div key={sealId} className={styles['item']}
                                             style={{backgroundImage: `url(${base64})`}}>
                                            {stampTag && <div className={stampClass}>{stampTag}</div>}
                                            {alias &&
                                                (<Tooltip title={alias} placement='bottom'
                                                          getPopupContainer={triggerNode => triggerNode.parentNode}>
                                                    <div className={styles['stamp-alias']} tooltip={alias}>{alias}</div>
                                                </Tooltip>)}
                                            {!usePermission && <div className={styles['no-permission_wraper']}>
                                                <Button key="applyBtn" disabled={applyLoading}
                                                        onClick={this.handleApplyStamp.bind(this, item)}>
                                                    {+authStatus === 1 ? t('button.urge') : t('button.applyPermission')}</Button>
                                            </div>}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </>}
                    {!loading && (!stampList || stampList.length < 1) &&
                        <div className={styles['stamp-list-msg']}>{t('tips.noAvailableSeals')}</div>}
                </section>
            </div>
        )
    }
}

export default withTranslation('sealPlugin')(ApplyOrgSeals);

