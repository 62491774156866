import React, {useEffect} from 'react';
import styles from "./index.module.less";
import {Button, Progress, Spin} from "antd";
import dayjs from "dayjs";
import {getStore} from "../../../store";
import {getNewSubscription} from "../../../request/esign";
import {useTranslation, Trans} from "react-i18next";
import {isDarkMode} from "../utils/util";

export function AccountContent() {

    const [tabType, setTabType] = React.useState(0);
    const [data, setData] = React.useState({});
    const [hasOut, setHasOut] = React.useState(false);
    const [hasIn, setHasIn] = React.useState(false);

    const { t } = useTranslation('account');

    useEffect(() => {
        const tenantId = getStore('userInfo').enterpriseId;
        let param = {};
        if (tenantId) {
            param.tenantId = tenantId;
        }
        getNewSubscription(param).then(res => {
            const { orgExternal, orgInternal, personExternal } = res;
            if (orgExternal && orgExternal.cert) {
                setTabType(3);
            } else if (personExternal && personExternal.cert) {
                setTabType(2);
            } else if (orgInternal && orgInternal.cert) {
                setTabType(1);
            } else {
                setTabType(-1);
            }
            if ((orgExternal && orgExternal.cert) || (personExternal && personExternal.cert)) {
                setHasOut(true);
            }
            if (orgInternal && orgInternal.cert) {
                setHasIn(true);
            }
            setData(res);
        });
    }, []);




    const changeTab = (type) => {
        if (type > 1 && hasOut) {
            const { orgExternal, personExternal } = data;
            if (orgExternal && orgExternal.cert) {
                setTabType(3);
            } else if (personExternal && personExternal.cert) {
                setTabType(2);
            }
        } else {
            hasIn && setTabType(type);
        }
    };

    let percent = 0;
    let total = 0;
    let date = '';
    let isOver = false;
    let text = '';

    if (tabType > 0) {

    }
    switch (tabType) {
        case 1:
            total = t('unlimited');
            date = data.orgInternal?.expreTime || '';
            isOver = +data.orgInternal?.expreTime - (+data.serverTime) < 0;
            percent = isOver ? 0 : 100;
            let tipColor = isDarkMode() ? '#bfa8ff' : '#5522E6'
            text = (<div className={styles.text}>
                <div><span style={{ color: isOver ? 'red' : tipColor }}>{t('unlimited')}</span></div>
            </div>);
            break;
        case 2:
        case 3:
            const extData = tabType === 2 ? data.personExternal : data.orgExternal;
            const usedNum = +extData.usedDocNumber || 0;
            const remainNum = +extData.docNumber || 0;
            total = usedNum + remainNum;
            if (total === 0 || remainNum === 0) {
                percent = 0;
            } else {
                percent = remainNum / total * 100;
            }

            date = extData?.expreTime || '';
            isOver = +extData?.expreTime - (+data.serverTime) < 0;
            const num = remainNum.toLocaleString('en-US');
            let color = isDarkMode() ? '#bfa8ff' : '#5522E6'
            text = (<div className={styles.text}>
             <Trans i18nKey="account:remainQuota" components={{ 1: <div></div> ,2: <span style={{ color: isOver || remainNum === 0? 'red' : color }}></span>}} tOptions={{num}}></Trans>
            </div>);
            break;
        default:
            break;
    }

    return (
        <div>
            {tabType < 1 ?
                <div className={styles.empty}>
                    {tabType === 0 && <Spin size={'large'} />}
                    {tabType< 0 && <span>{t('noData')}</span>}
                </div>
                : <>
                    <div className={styles.tabs}>
                        <div className={`${styles.tab} ${tabType > 1 ? styles.active : ''} ${!hasOut ? styles.disabled : ''}`}
                             onClick={() => changeTab(2)}>{t('common:wardType0')}
                        </div>
                        <div className={`${styles.tab} ${tabType < 2 ? styles.active : ''} ${!hasIn ? styles.disabled : ''}`}
                             onClick={() => changeTab(1)}>{t('common:wardType1')}
                        </div>
                    </div>
                    <div className={styles.process}>
                        <Progress type="circle" percent={percent}
                                  format={() => text}
                                  strokeColor={{
                                      '100%': '#A236B2',
                                      '0%': '#8B18E6',
                                  }}
                                  strokeWidth={10} />
                    </div>
                    <div className={styles.date}>
                        <span>{dayjs(date * 1000).format('YYYY-MM-DD HH:mm:ss')}</span>
                        <span style={{ color: isOver ? 'red' : '' }}> {isOver ? t('account:expire') : t('account:unexpire')}</span>
                    </div>
                </>}

            <div esigncn-point-modal="true">
                <Button ghost={true} type={'primary'}
                        onClick={() => {
                            window.open('https://www.foxitsoftware.cn/esign#sales');
                        }}
                >{t('buy')}</Button>
            </div>


            <ul className={styles.tips}>
                <li>{t('desc')}</li>
                <li>{t('desc2')}</li>
            </ul>

            {tabType > 1 && <div className={styles.outType}>
                {tabType === 2 ? t('common:signType1') : t('common:signType2')}
            </div>}
        </div>
    )
}