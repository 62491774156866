export const INIT_FLOW_SAVE_TYPE = {
    ONLINE: "online",
    LOCAL: "local",
}
export const AUDIT_TRAIL_PATH = '/signing-report'
export const E_QIAN_BAO_DOMAINS = ['https://h5.esign.cn', 'https://smlh5.esign.cn']

export const SDK_CODE = {
    SUCCESS: 0,
    // engine加载文档失败
    UPLOAD_DOC_FAIL_ENCRYPTED: 1,
    UPLOAD_DOC_FAIL_UN_KNOW: 2,

    // 签名校验失败
    NO_SIG: 3,
}

export const PUBLIC_SEAL_TYPE = {
    CREATE_SEAL: 'create_seal', // 创建对内印章 或 创建 对外临时印章
    OUT_SEAL_ADMIN: 'out_seal_admin', // 对外印章管理
}

export const PHANTOM_STAMP_TYPE = {
    OUT_ORG_ADMIN: '1', // 对外企业章管理
    OUT_PERSON_ADMIN: '2', // 对外个人章管理
    OUT_PERSON_SEAL_CREATE: '3' // 对外个人章创建
}

export const CREATE_SEAL_TYPE = {
    OUT_PERSON_SEAL: 'out_person_seal', // 对外个人印章
    OUT_ORG_SEAL: 'out_company_seal', // 对外公司印章
}

export const SUPPORT_DARK_THEME_ROUTES = ['/sign_record']