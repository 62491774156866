import styles from "../index.module.less";
import {FileImageOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import {errorMessage} from "../../../pages/components/Message";
import {getBase64, getBlackBase64, isIE} from "../../../utils";
import {Button, Checkbox} from "antd";
import { useTranslation } from 'react-i18next';


export function ExtPaste(props) {
    const {setPasteClear, hasImg} = props;
    const { t } = useTranslation('sealPlugin');
    const clearData = () => {
        setPasteClear(new Date().getTime());
    }
    return (
        <Button hidden={!hasImg} type={"link"} onClick={clearData}>{t('button.paste')}</Button>
    )
}

export default function CreatePaste(props) {
    const [isBlack, setIsBlack] = useState(true);
    const {setPasteImg, pasteClear} = props
    const [imgUrl, setImgUrl] = useState('');
    const [file, setFile] = useState(undefined);
    const { t } = useTranslation('sealPlugin');

    useEffect(() => {
        if (!file) {
            return
        }
        if (isBlack) {
            getBlackBase64(file).then(base64 => {
                setImgUrl(base64);
                setPasteImg(base64);
            });
        } else {
            getBase64(file).then(base64 => {
                setImgUrl(base64);
                setPasteImg(base64);
            });
        }
    }, [file, isBlack]);

    useEffect(() => {
        if (pasteClear) {
            pasteImage();
        }
    }, [pasteClear]);

    const pasteImage = () => {
        getClipboardData(t).then(blob => {
            if (!blob) {
                errorMessage(t('message.noImageInClipboard'));
                return;
            }
            if (blob.size > 1024 * 200) {
                errorMessage(t('message.imageSizeLimit'));
                return;
            }
            setFile(blob)
        })
    }

    return (<>
            <div className={styles.tabContainer + ' ' + styles.tabUpload} onClick={pasteImage}>

                <div className={styles.uploadPaste}>
                    {imgUrl ? (
                        <img src={imgUrl} alt=""/>
                    ) : (
                        <>
                            <p>
                                <FileImageOutlined/>
                            </p>
                            <p>{t('tips.clickToPaste')}</p>
                            <p style={{color: '#ccc'}}>
                                {t('tips.pasteAuthRequired')}
                            </p>
                        </>
                    )}
                </div>

            </div>
            <div style={{marginTop: 20, userSelect: 'none'}}>
                <Checkbox style={{color:"var(--text-color)"}} checked={isBlack} onChange={e => setIsBlack(e.target.checked)}>
                    {t('tips.convertToBlackAndWhite')}</Checkbox>
            </div>
        </>
    )
}

function getClipboardData(t) {
    if (!navigator.clipboard || typeof navigator.clipboard.read !== 'function' || isIE) {
        errorMessage(t('message.browserNotSupported'));
        return Promise.resolve(null);
    }
    if (!window.isSecureContext) {
        errorMessage(t('message.browserNotSupported'));
        return Promise.resolve(null);
    }

    return navigator.clipboard.read().then(clipboardData => {
        const item = (clipboardData || [])[0];
        if (!item) {
            return null;
        }
        const imageIndex = item.types.findIndex(item => item.indexOf('image/') === 0);
        if (imageIndex >= 0) {
            return item.getType(item.types[imageIndex]);
        }
    }).catch(e => {
        return null;
    });
}
