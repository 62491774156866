import styles from "./index.module.less";
import {Button, Modal, Skeleton, Tooltip} from "antd";
import {DeleteOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import CreateSeal from "./createInnerSeals/CreateSeal";
import {useEffect, useState} from "react";
import {errorMessage, loadingMessage, successMessage} from "../../pages/components/Message";
import {getInnerSealList, innerSealDisable} from "../../request/esign";
import {getParams} from "../../utils";
import {sendMsg} from "./index";
import { useTranslation } from 'react-i18next';


export default function InnerList() {
    const { t } = useTranslation('sealPlugin');
    const [seals, setSeals] = useState([]);
    const [addVisible, setAddVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const queryParams = getParams() || {};
    const {token, enterpriseId} = queryParams;

    useEffect(() => {
        sendMsg({type: 'resize', width: '640px', height: '408px'});
        setIsLoading(true);
        if (!token || !enterpriseId) {
            return;
        }
        getInnerSealList(token, enterpriseId).then(seals => {
            if (!seals || seals.length < 1) {
                setSeals([]);
            } else {
                setSeals(seals);
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }, [token, enterpriseId])

    const handleDelte = (sealId) => {
        let params;
        params = {
            title: t('title.sealList'),
            icon: <ExclamationCircleOutlined/>,
            content: t('message.deleteConfirm'),
            okText: t('button.confirm'),
            cancelText: t('button.cancel'),
            okButtonProps: {},
            cancelButtonProps: {},
            onOk() {
                loadingMessage(t('message.deletingStamp'))
                innerSealDisable({token, enterpriseId, sealId}).then(() => {
                    const newSeals = seals.filter(item => item.id !== sealId);
                    setSeals(newSeals);
                    successMessage(t('message.deleteSuccess'));
                }).catch(() => {
                    errorMessage(t('message.deleteFailed'));
                })
            },
            onCancel() {
            }
        }
        Modal.confirm(params);
    }

    const closeAdd = (data) => {
        sendMsg({type: 'resize', width: '640px', height: '408px'});
        setAddVisible(false);
        if (data) {
            const newSeals = [data, ...seals];
            setSeals(newSeals);
        }
    }

    const handleAddStamp = () => {
        setAddVisible(true)
    }

    return (
        <>
            {!addVisible && <div className={styles.innerBoxContainer}>
                <Skeleton active loading={isLoading}  className={styles.skeleton}>
                {(seals && seals.length > 0) && <div className={styles.addBtn}>
                    <Button type='text' onClick={handleAddStamp} size={"small"} style={{color: 'var(--primary-color)'}}>+
                        {t('button.add')}</Button></div>
                }
                <div className={styles.innerBox}>
                    {
                        Array.isArray(seals) && seals.length > 0 &&
                        <div className={styles.list}>
                            {
                                seals.map((item) => {
                                    return (
                                        <div className={styles.item} key={item.id}>
                                            <div className={styles.img}>
                                                <img src={item.base64} alt={'seal'}/>
                                            </div>
                                            <div className={styles.cover}>
                                                <Tooltip title={t('button.deleteStamp')} className={styles.delBtn}>
                                                    <Button onClick={() => handleDelte(item.id)}
                                                            shape="circle" icon={<DeleteOutlined/>}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                    {
                        (!seals || seals.length < 1) &&
                        <div className={styles.emptyContainer}>
                            <div className={styles.emptyIcon}></div>
                            <Button ghost={true}  onClick={handleAddStamp}
                                    type="primary">{t('button.addStamp')}</Button>
                        </div>
                    }
                </div>
                </Skeleton></div>}
            {addVisible && <CreateSeal close={closeAdd}></CreateSeal>}
        </>

    )
}
