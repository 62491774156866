import React, {useEffect} from 'react';
import styles from './index.module.less';
import {Button, Input} from "antd";
import Icon from '@ant-design/icons';
import {getStore} from "../../../store";
import {getQueryVariable} from "../service";
import {createNps} from "../../../request/commonService";
import {
    CloseOutlined,
} from '@ant-design/icons';
import {closeESignCNDialogAPI, setCacheForPhantom} from "../../../request/phantom";
import {resizeDialog} from "../Model";

const { TextArea } = Input;


const UnhappyIcon =  () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.94681 0C6.37508 0 4.83865 0.469192 3.5318 1.34824C2.22496 2.22729 1.20639 3.47672 0.604918 4.93853C0.00344327 6.40034 -0.15393 8.00887 0.152699 9.56072C0.459329 11.1126 1.21619 12.538 2.32757 13.6569C3.43895 14.7757 4.85494 15.5376 6.39647 15.8463C7.93799 16.155 9.53583 15.9965 10.9879 15.391C12.44 14.7855 13.6811 13.7602 14.5543 12.4446C15.4275 11.129 15.8936 9.58225 15.8936 8C15.8912 5.879 15.0532 3.84556 13.5634 2.34579C12.0736 0.846017 10.0537 0.00239359 7.94681 0Z" fill="#E98559"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M6.58203 15.8818C7.59906 15.8353 8.60584 15.6117 9.55633 15.2153C11.1674 14.5435 12.5444 13.4059 13.5132 11.9463C14.482 10.4867 14.999 8.77065 14.999 7.0152C14.9973 5.45067 14.5857 3.92908 13.8243 2.59589C15.2179 4.06849 15.9979 6.01308 16.0002 8.03784C16.0002 9.61259 15.5287 11.152 14.6454 12.4613C13.7621 13.7707 12.5066 14.7912 11.0377 15.3938C9.62804 15.9721 8.0826 16.1407 6.58203 15.8818Z" fill="#E86B52"/>
        <rect x="5" y="9.06024" width="6" height="3.6129" rx="1" fill="black"/>
        <rect x="6.11133" y="9.06024" width="3.77778" height="1.54839" fill="white"/>
        <rect x="6.11133" y="11.1248" width="3.77778" height="1.54839" fill="white"/>
        <path d="M3.20508 5.5238L6.77199 6.06838L6.74986 6.26136C6.63635 7.25126 5.74584 7.93183 4.76087 7.78145C3.77589 7.63107 3.06943 6.70669 3.18295 5.71678L3.20508 5.5238Z" fill="white"/>
        <ellipse cx="4.93883" cy="6.50407" rx="0.546248" ry="0.630293" fill="black"/>
        <path d="M3.00504 5.46896C3.00504 5.46896 6.05162 5.9297 6.45951 5.88557C6.8674 5.84145 6.99985 5.469 6.99985 5.469" stroke="#B7351C" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.7715 5.5238L9.20457 6.06838L9.2267 6.26136C9.34022 7.25126 10.2307 7.93183 11.2157 7.78145C12.2007 7.63107 12.9071 6.70669 12.7936 5.71678L12.7715 5.5238Z" fill="white"/>
        <ellipse cx="0.546248" cy="0.630293" rx="0.546248" ry="0.630293" transform="matrix(-1 0 0 1 11.584 5.87378)" fill="black"/>
        <path d="M12.9715 5.46896C12.9715 5.46896 9.92494 5.9297 9.51705 5.88557C9.10917 5.84145 8.97671 5.469 8.97671 5.469" stroke="#B7351C" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

// 态度一般的icon组件
const NormalIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346632 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9976 5.879 15.154 3.84556 13.6542 2.34579C12.1544 0.846017 10.121 0.00239359 8 0Z" fill="#FFE17D"/>
        <path d="M6 10H10" stroke="#9C6846" strokeLinecap="round"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M3.30473 1.52267C2.60524 2.22352 2.04204 3.041 1.64703 3.93854C1.00368 5.40035 0.835355 7.00889 1.16333 8.56073C1.4913 10.1126 2.30085 11.538 3.4896 12.6569C4.67834 13.7757 6.1929 14.5376 7.84173 14.8463C9.49057 15.155 11.1996 14.9965 12.7528 14.391C12.7943 14.3749 12.8355 14.3584 12.8767 14.3417C12.3232 14.7673 11.7135 15.1209 11.0615 15.3909C9.59966 15.9964 7.99113 16.1549 6.43928 15.8462C4.88743 15.5375 3.46197 14.7756 2.34315 13.6567C1.22433 12.5379 0.462403 11.1125 0.153721 9.56062C-0.15496 8.00877 0.0034663 6.40024 0.608967 4.93843C1.17544 3.57083 2.10942 2.38912 3.30473 1.52267Z" fill="#FFD164"/>
        <path d="M5.56522 5.40587C5.56522 5.56065 5.51932 5.71196 5.43332 5.84066C5.34733 5.96936 5.2251 6.06967 5.0821 6.1289C4.9391 6.18814 4.78174 6.20364 4.62993 6.17344C4.47812 6.14324 4.33867 6.06871 4.22922 5.95926C4.11977 5.84981 4.04524 5.71036 4.01504 5.55855C3.98484 5.40674 4.00034 5.24938 4.05957 5.10638C4.11881 4.96337 4.21912 4.84115 4.34782 4.75515C4.47651 4.66916 4.62782 4.62326 4.78261 4.62326C4.99015 4.62334 5.18916 4.70581 5.33591 4.85257C5.48266 4.99932 5.56514 5.19833 5.56522 5.40587Z" fill="#9C6846"/>
        <path d="M12.0867 5.40584C12.0867 5.56062 12.0408 5.71193 11.9548 5.84063C11.8688 5.96933 11.7466 6.06964 11.6036 6.12887C11.4606 6.18811 11.3032 6.20361 11.1514 6.17341C10.9996 6.14321 10.8602 6.06868 10.7507 5.95923C10.6413 5.84978 10.5667 5.71033 10.5365 5.55852C10.5063 5.40671 10.5218 5.24935 10.5811 5.10635C10.6403 4.96334 10.7406 4.84112 10.8693 4.75512C10.998 4.66913 11.1493 4.62323 11.3041 4.62323C11.5116 4.62331 11.7106 4.70578 11.8574 4.85253C12.0041 4.99929 12.0866 5.1983 12.0867 5.40584Z" fill="#9C6846"/>
    </svg>
)

// 高兴的icon组件
const HappyIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346632 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9976 5.879 15.154 3.84556 13.6542 2.34579C12.1544 0.846017 10.121 0.00239359 8 0Z" fill="#FFE17D"/>
        <path d="M3.04688 5.95239C3.41494 5.10303 4.3849 4.72041 5.2133 5.09777C5.5848 5.26697 5.88181 5.57151 6.04688 5.95239" stroke="#9C6846" strokeMiterlimit="10" strokeLinecap="round"/>
        <path d="M10.0469 5.95239C10.4149 5.10303 11.3849 4.72041 12.2133 5.09777C12.5848 5.26697 12.8818 5.57151 13.0469 5.95239" stroke="#9C6846" strokeMiterlimit="10" strokeLinecap="round"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M1.36741 3.42328C0.771201 4.98267 0.626638 6.68046 0.952982 8.3211C1.29522 10.0416 2.13996 11.622 3.38039 12.8625C4.62082 14.1029 6.20123 14.9476 7.92175 15.2899C8.88108 15.4807 9.85996 15.5105 10.8193 15.3832C9.42311 15.9089 7.90588 16.0344 6.43928 15.7427C4.88743 15.434 3.46197 14.6721 2.34315 13.5532C1.22433 12.4344 0.462403 11.009 0.153721 9.45711C-0.15496 7.90527 0.0034663 6.29673 0.608967 4.83493C0.81491 4.33774 1.06942 3.86512 1.36741 3.42328Z" fill="#FFD164"/>
        <path d="M10.4105 9.5238C9.1748 10.5673 7.01919 10.5673 5.71484 9.5238" stroke="#F0915C" strokeLinecap="round"/>
        <path d="M8.82609 12C8.34554 12.3478 7.50725 12.3478 7 12" stroke="#F4BE66" strokeLinecap="round"/>
        <path d="M2.56691 7.61905C2.36053 7.61905 2.15879 7.68025 1.98719 7.79491C1.81559 7.90956 1.68185 8.07253 1.60287 8.2632C1.52389 8.45387 1.50323 8.66368 1.54349 8.86609C1.58375 9.06851 1.68313 9.25444 1.82906 9.40037C1.975 9.5463 2.16093 9.64568 2.36334 9.68595C2.56575 9.72621 2.77556 9.70554 2.96623 9.62657C3.1569 9.54759 3.31987 9.41384 3.43453 9.24225C3.54919 9.07065 3.61038 8.8689 3.61038 8.66252C3.61007 8.38587 3.50004 8.12064 3.30441 7.92502C3.10879 7.7294 2.84356 7.61936 2.56691 7.61905Z" fill="#EF8758"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M1.75521 8.00641C1.73254 8.1421 1.73443 8.28148 1.76155 8.4178C1.80565 8.63954 1.91452 8.84322 2.07439 9.00309C2.23425 9.16295 2.43793 9.27182 2.65967 9.31593C2.88141 9.36003 3.11125 9.3374 3.32012 9.25088C3.37503 9.22813 3.42785 9.20124 3.47816 9.17054C3.46461 9.19482 3.45006 9.21862 3.43453 9.24186C3.31987 9.41346 3.1569 9.54721 2.96623 9.62618C2.77556 9.70516 2.56575 9.72583 2.36334 9.68556C2.16093 9.6453 1.975 9.54592 1.82906 9.39999C1.68313 9.25406 1.58375 9.06813 1.54349 8.86571C1.50323 8.6633 1.52389 8.45349 1.60287 8.26282C1.64132 8.16999 1.69275 8.08373 1.75521 8.00641Z" fill="#EC654C"/>
        <path d="M13.5239 7.61905C13.3176 7.61905 13.1158 7.68025 12.9442 7.79491C12.7726 7.90956 12.6389 8.07253 12.5599 8.2632C12.4809 8.45387 12.4603 8.66368 12.5005 8.86609C12.5408 9.06851 12.6402 9.25444 12.7861 9.40037C12.932 9.5463 13.118 9.64568 13.3204 9.68595C13.5228 9.72621 13.7326 9.70554 13.9233 9.62657C14.1139 9.54759 14.2769 9.41384 14.3916 9.24225C14.5062 9.07065 14.5674 8.8689 14.5674 8.66252C14.5671 8.38587 14.4571 8.12064 14.2614 7.92502C14.0658 7.7294 13.8006 7.61936 13.5239 7.61905Z" fill="#EF8758"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.7125 8.00641C12.6898 8.14219 12.6916 8.28168 12.7188 8.41809C12.7629 8.63983 12.8717 8.84351 13.0316 9.00338C13.1915 9.16324 13.3952 9.27211 13.6169 9.31622C13.8386 9.36032 14.0685 9.33769 14.2773 9.25117C14.3321 9.22847 14.3849 9.20163 14.4351 9.171C14.4216 9.19523 14.4071 9.21897 14.3916 9.24215C14.2769 9.41375 14.1139 9.5475 13.9233 9.62647C13.7326 9.70545 13.5228 9.72612 13.3204 9.68585C13.118 9.64559 12.932 9.54621 12.7861 9.40028C12.6402 9.25435 12.5408 9.06842 12.5005 8.866C12.4603 8.66359 12.4809 8.45378 12.5599 8.26311C12.5984 8.17017 12.6499 8.0838 12.7125 8.00641Z" fill="#EC654C"/>
    </svg>

)



const rateData = [
    {
        value: 1,
        text: '非常不愿意',
        color: '#E74446',
        desc: '完全不愿意推荐',
        icon: <Icon component={UnhappyIcon} className={styles.unhappyIcon}/>
    },
    {
        value: 2,
        text: '',
        color: '#E74446',
        desc: '完全不愿意推荐',
        icon: <Icon component={UnhappyIcon} className={styles.unhappyIcon}/>
    },
    {
        value: 3,
        text: '',
        color: '#E74446',
        desc: '完全不愿意推荐',
        icon: <Icon component={UnhappyIcon} className={styles.unhappyIcon}/>
    },
    {
        value: 4,
        text: '',
        color: '#E74446',
        desc: '完全不愿意推荐',
        icon: <Icon component={UnhappyIcon} className={styles.unhappyIcon}/>
    },
    {
        value: 5,
        text: '',
        color: '#E74446',
        desc: '完全不愿意推荐',
        icon: <Icon component={UnhappyIcon} className={styles.unhappyIcon}/>
    },
    {
        value: 6,
        text: '',
        color: '#E74446',
        desc: '完全不愿意推荐',
        icon: <Icon component={UnhappyIcon} className={styles.unhappyIcon}/>
    },
    {
        value: 7,
        text: '',
        color: '#F4DE77',
        desc: '不确定是否推荐',
        icon: <Icon component={NormalIcon} className={styles.normalIcon}/>
    },
    {
        value: 8,
        text: '',
        color: '#F4DE77',
        desc: '不确定是否推荐',
        icon: <Icon component={NormalIcon} className={styles.normalIcon}/>
    },
    {
        value: 9,
        text: '',
        color: '#92CD62',
        desc: '非常愿意推荐',
        icon: <Icon component={HappyIcon} className={styles.happyIcon}/>
    },
    {
        value: 10,
        text: '非常愿意',
        color: '#92CD62',
        desc: '非常愿意推荐',
        icon: <Icon component={HappyIcon} className={styles.happyIcon}/>
    }
]

function Rate({ rateList, value, onChange }) {

    function handleClick(item) {
        onChange(item.value);
    }

    return (
        <div className={styles.ratingWrap}>
            {
                typeof value === 'number' &&
                <div className={styles.flexRowCenter}>
                    {rateList.find(item => item.value === value).icon}&nbsp;&nbsp;
                    {rateList.find(item => item.value === value).desc}
                </div>
            }
            <div className={styles.ratingContainer + ' rate-btn'}>
                {rateList.map((item, index) => (
                    <Button
                        key={index}
                        onClick={() => handleClick(item)}
                        style={{ backgroundColor: value === item.value ? item.color : 'inherit' }}
                        className={ value === item.value ? styles.rateBtn + ' ' + styles.active : styles.rateBtn}
                    >
                        {index + 1}
                    </Button>
                ))}
            </div>
            <div className={styles.indicatorBar}></div>
            <div className={styles.flexRowJustify} style={{ color: '#ACACAC'}}>
                <span>{rateList[0].text}</span>
                <span>{rateList[rateList.length-1].text}</span>
            </div>
        </div>

);
}

function Result({ onClose }) {

    useEffect(() => {
        let timer = setTimeout(() => {
            closeESignCNDialogAPI()
            clearTimeout(timer);
            timer = null;
        }, 2000)
    }, [])

    return (
        <div className={styles.result}>
            <CloseOutlined className={styles.close} onClick={onClose}/>
            <div className={styles.resultWrap}>
                <div className={styles.img}></div>
                <div>感谢您的反馈！</div>
                <div>我们会继续努力，为您提供更优质的服务。</div>
            </div>
        </div>
    );
}

const EsignNps = () => {
    const [value, setValue] = React.useState();
    const [feedback, setFeedback] = React.useState('');
    const [showResult, setShowResult] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const handleChange = val => {
        setValue(val);
        handleResizeDialog(val);
    };

    const handleTextAreaChange = (e) => {
        setFeedback(e.target.value);
    }

    const handleSubmit = () => {
        setLoading(true);
        const org_id = getStore('userInfo').enterpriseId;
        const org_name = getStore('userInfo').enterpriseName;
        const ward_type = getQueryVariable('wardType') || '';
        const data = {
            score: value,
            feedback: value < 7 && !!feedback ? feedback : '',
            org_id,
            org_name,
            ward_type
        }
        createNps(data).then(res => {
            console.log('res', res);
            setShowResult(true);
        }).catch( e => {
            console.error('createNps e: ', e);
        }).finally(() => {
            setLoading(false);
        })
    }

    function handleClose() {
        closeESignCNDialogAPI().catch(e => {
            console.error('closeEsignNps e: ', e);
        })
    }

    function handleResizeDialog(score) {
        let size = { width: 432, height: 196 }
        if (typeof score !== "number") {
            size = { width: 432, height: 196 }
        } else if (score < 7) {
            size = { width: 432, height: 350 }
        } else {
            size = { width: 432, height: 264 }
        }
        resizeDialog(size)
    }

    function setDefaultSize() {
        let size = { width: 432, height: 196 }
        resizeDialog(size)
    }

    function setNpsFlag() {
        let key = `NPS_${getStore('userInfo').cUserID || ''}`;
        setCacheForPhantom({ type: 2, key, data: 1 })
    }

    useEffect(() => {
        document.body.style.backgroundColor = '#F5F6FA';
        setNpsFlag();
        setDefaultSize()
    }, [])

    if (showResult) {
        return <Result onClose={handleClose}/>;
    }

    return (
        <div className={styles.wrap}>
            <div className={styles.close} onClick={handleClose}><CloseOutlined/></div>
            <div className={styles.container + ' esign_nps'}>
                <div className={styles.title}>您是否会将福昕电子签章服务推荐给身边的朋友？</div>
                <div className={styles.main}>
                    <Rate
                        value={value}
                        rateList={rateData}
                        onChange={handleChange}
                    />
                    {
                        value < 7 && (
                            <div className={styles.feedbackForm}>
                                <div>请告诉我们出了什么问题？</div>
                                <TextArea
                                    value={feedback}
                                    autoSize={{ minRows: 2, maxRows: 2 }}
                                    allowClear
                                    placeholder='恳请您描述您的想法，助力我们改进服务。'
                                    maxLength={200}
                                    onChange={handleTextAreaChange}
                                    showCount={true}
                                />
                            </div>
                        )
                    }
                </div>
                {
                    typeof value === 'number' &&
                    <Button
                        type='primary'
                        block
                        className={styles.submitBtn}
                        loading={loading}
                        onClick={handleSubmit}
                    >
                        提交
                    </Button>
                }
            </div>
        </div>
    );
};

export default EsignNps;