import { ConfigProvider, Modal } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import React  from 'react';
import {AccountContent} from "./content";
import {useTranslation} from "react-i18next";

export default function Account(props) {

    const { t } = useTranslation();

    const handleCancel = () => {
        props.close();
    };

    return (
        <ConfigProvider locale={zhCN}>
            <Modal
                title={t('account:title')}
                visible={true}
                width={400}
                bodyStyle={{ minHeight: 140 }}
                centered
                onCancel={handleCancel}
                footer={null}
            >
                <AccountContent/>
            </Modal>
        </ConfigProvider>
    );
}