import React, {useState} from 'react';
import styles from "./index.module.less";
import {Tabs} from "antd";
import {getParams} from "../../utils";
import OutPanel from "./OutPanel";
import {sendMsg} from "./index";
import { useTranslation } from 'react-i18next';

export default function OuterList({close}) {
    const { t } = useTranslation('sealPlugin');
    const [tabIndex, setTabIndex] = useState(0);

    const queryParams = getParams() || {};
    const {token, accountId, orgId, enterpriseId, trueName} = queryParams;
    if (!token || !accountId) {
        close();
        return;
    }

    sendMsg({type: 'resize', width: '890px', height: '652px'});

    const items = [
        { label: t('title.personalSeal'), key: 0, children: <OutPanel accountId={accountId} token={token} hasTab={true} />  },
    ];

    if (orgId) {
        items.push({ label: t('title.companySeal'), key: 1, children:
                <OutPanel hasTab={true} trueName={trueName} accountId={accountId} enterpriseId={enterpriseId} orgId={orgId} token={token} /> })
    }

    const tabChange = (key) => {
        setTabIndex(key);
    };

    return (
        <div className={styles.tabs}>
            <Tabs defaultActiveKey={tabIndex}
                  onChange={tabChange} items={items}>
            </Tabs>
        </div>
    );
}