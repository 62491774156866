import {getUserInfoFromContainer, getAssignInfoForPhantom} from "./Model";
import {getUserInfo} from "../../request/user";
import {getStore, setStore} from "../../store";
import {hideMessage, loadingMessage} from "../components/Message";
import {
    binding,
    checkAuth,
    getInnerSealList,
    getOrgSeals,
    getPersonCreateUrl,
    getPersonSeals, searchFlowMan, searchOrgNameForPhantom
} from "../../request/esign";
import {closeESignCNDialogAPI, openUrlByBrowser, showESignCNDialogAPI} from "../../request/phantom";
import {globalFnMap, mockSwitch, needLoadStampPages, noNeedTokenPages, noNeedUserInfoPages} from "./config";
import {formatPdfInfo} from "./PhantomSignFlowPanel/service";
import {
    ERROR_AC_FAIL,
    ERROR_AC_ORG_PERMISSION_FAIL,
    ERROR_AC_PERMISSION_FAIL,
    ERROR_EQB_AUTH_EXPIRED, ERROR_EQB_ORG_TOKEN_EXPIRED
} from "../../request/error_container";
import {inOnline, inPhantom} from "./utils/util";
import {getUrlConfig} from "../../init";
import otelApi from "../../utils/otel/api";
import {AUDIT_TRAIL_QUERY} from "./auditTrail";
import {AUDIT_TRAIL_PATH, E_QIAN_BAO_DOMAINS, SUPPORT_DARK_THEME_ROUTES} from "./constant";
import pluginRoutes from "../../routes/pluginRoutes";

export function isPurePluginView() {
    const pathName = window.location.pathname;
    return pluginRoutes.find(it => it.path === pathName)?.purePlugin;
}

export function checkAuditTrailQuery() {
    const query = sessionStorage.getItem(AUDIT_TRAIL_QUERY);
    if (query) {
        sessionStorage.removeItem(AUDIT_TRAIL_QUERY);
        const ticket = getQueryVariable('ticket');
        window.location.href = window.location.origin + AUDIT_TRAIL_PATH + query + '&ticket=' + ticket;
    }
}

export function checkPublicPageEnv (callback) {
    let isPublicPageEnv = false;
    checkAuditTrailQuery();
    // const ua = window.navigator.userAgent;
    // let phantomUAIndex = ua.indexOf(PHANTOM_UA_KEY);
    // if (inPhantom()) {
    //     const versionStr = ua.slice(phantomUAIndex + PHANTOM_UA_KEY.length + 1).split(' ')[0]
    //     const versionArr = versionStr.split('.').map(i => parseInt(i.replace(/[^0-9]/ig, ""), 10))
    //     setStore('plugin', { ...getStore('plugin'), versionArr })
    // }
    if (inPhantom() || inOnline() || mockSwitch) {
        initPluginEnv({ inPhantom: inPhantom(), inOnline: inOnline(), callback })
        isPublicPageEnv = true;
    }
    return isPublicPageEnv;
}

export function initPluginEnv({ inPhantom, inOnline, callback }) {
    if (inIframeEnvFn()) {
        return;
    }
    loadTheme();
    document.body.setAttribute('key', 'plugin-container')
    document.body.classList.add('phantom-container')
    initMountOnWindow();
    getUrlConfig()
        .then(() => {
            return otelApi.initOtel(getStore('configUrls').otelTraceUrl, 'cloud-editor-tracer', '1.0.0')
        })
        .then(() => {
            const pluginStore = getStore('plugin')
            setStore('plugin', { ...pluginStore, inPhantom, inOnline })
            return Promise.resolve();
        })
        .then(() => {
            return initPluginService(callback)
        })

}

export default function initPluginService (callback) {
    if(isPurePluginView()) {
        callback && callback();
        return Promise.resolve();
    }
    const path = window.location.pathname;
    if (noNeedUserInfoPages.includes(path)) {
        callback && callback()
        return Promise.resolve();
    }
    return getUserInfoFromContainer().then(data => {
        if (data.pluginVersion) {
            const pluginVersionArr = data.pluginVersion.split('.').map(i => parseInt(i.replace(/[^0-9]/ig, ""), 10))
            setStore('plugin', { ...getStore('plugin'), pluginVersionArr })
        }
        if (noNeedTokenPages.includes(path)) { // 如果当前页面不需要获取用户token，则不需要走下面的初始化流程。
            callback && callback()
            return Promise.resolve();
        }
        if (!data.token) {
            console.error('valid token', data.token)
            return;
        }
        const wardType = parseInt(getQueryVariable('wardType'))
        return getUserInfo(data.token).then(info => {
            info.token = data.token;
            if (data.enterpriseId && data.enterpriseId !== 'undefined') {
                info.enterpriseId = data.enterpriseId;
                info.enterpriseName = data.enterpriseName;
                info.pluginVersion = data.pluginVersion;
            }
            info.userMobile = info.userMobile && info.userMobile.slice(-11);
            setStore('userStatus', 1);
            setStore('userInfo', info);
            setStore('plugin', { ...getStore('plugin'), wardType })
            globalFnBind()
            return data;
        }).then(() => {
            if (wardType === 0) {
                return _getAuth()
            } else {
                return Promise.resolve()
            }
        })
        .then( res => {
            let task = Promise.resolve();
            if(res && res.error) {
                return task;
            }
            const index = needLoadStampPages.findIndex(it => it.path === path);
            if (index > -1) {
                const silent = needLoadStampPages[index].silent
                task = getSealsInPlugin({ company: data.enterpriseName, companyId: getStore('userInfo').enterpriseId, silent})
            }
            return task;
        })
        .then(() => {
            const curPath = window.location.pathname
            const fn = globalFnMap[curPath]
            return fn && fn()
        })
        .then(() => {
            callback && callback()
        })
        .catch(e => {
            console.log(e)
            return false;
        })
    })
}

export function getSealsInPlugin({company, companyId, silent = false}) {
    const wardType = getStore('plugin').wardType;
    const {orgId, accountId} = getStore('userInfo');
    !silent && loadingMessage('正在载入印章…');
    return _requestSeals(accountId, orgId, company, companyId, wardType).then(([inResult,outOrgResult,  outPersonResult, ]) => {
        const inSeals = inResult && inResult.seals;
        const outPersonSeals = outPersonResult && outPersonResult.seals;
        const outOrgSeals = outOrgResult && outOrgResult.seals;
        setStore('plugin', { ...getStore('plugin'), outOrgSeals, outPersonSeals, inSeals })
        let sealsError = false;
        if ((inResult && inResult.sealsError) || (outPersonResult && outPersonResult.sealsError) || (outOrgResult && outOrgResult.sealsError)) {
            sealsError = true;
        }
        const phantomStore = getStore('plugin');
        setStore('plugin', { ...phantomStore, sealsError })

        // const { seals, inSeals, outPersonSeals, outOrgSeals, sealsError } = res;
        // console.log('seals', seals)
        // if (sealsError) {
        //     // eventManager.emit(SetSealsEvent, null);
        // } else {
        //     // eventManager.emit(SetSealsEvent, seals);
        //
        // }
        // if (msg) {
        //     delete res.seals;
        //     delete res.sealsError;
        //     return res;
        // }
        return null;
    }).finally(() => {
        !silent && hideMessage();
    });
}

function _requestSeals(accountId, orgId, company, companyId, wardType) {
    const taskArr = [Promise.resolve(), Promise.resolve(), Promise.resolve()]
    if (wardType === 1) {
        const result = {
            msg: null,
            link: null,
            linkTxt: null,
            linkTarget: '_blank',
            seals: [],
            sealsError: false,
        }
        const task = getInnerSealList().then(seals => {
            if (!seals || seals.length < 1) {
                // Modal.info({
                //     title: '福昕电子签章服务',
                //     icon: "",
                //     content: <span style={{display: 'flex', alignItems: 'center'}}><ExclamationCircleOutlined style={{fontSize: '22px', color: '#faad14'}}/><span style={{flex: 1, marginLeft: '8px'}}>未能获取到印章，请在[印章管理]功能内添加。</span></span>,
                //     zIndex: 99999,
                //     okText: "确认",
                //     mask: false,
                //     closable: true,
                //     className: "eSignCN_funcDialog_container"
                // })
                showESignCNDialogAPI({
                    title: "福昕电子签章服务",
                    type: "Dialog",
                    id: "ESIGNCN_IN_SIGN_SEALS_LACK",
                    msg: "未能获取到印章，请在[印章管理]功能内添加。"
                })
            } else {
                result.seals = seals;
            }
            return result;
        }).catch(key => {
            if (ERROR_AC_ORG_PERMISSION_FAIL === key) {
                // Modal.confirm({
                //     title: '福昕电子签章服务',
                //     icon: "",
                //     content: <span style={{display: 'flex', alignItems: 'center'}}><ExclamationCircleOutlined style={{fontSize: '22px', color: '#faad14'}}/><span style={{flex: 1, marginLeft: '8px'}}>感谢您对福昕电子签章服务的关注，对内签署需联系销售为您所在企业创建信息。</span></span>,
                //     zIndex: 99999,
                //     okText: "联系销售",
                //     cancelText: "取消",
                //     mask: false,
                //     closable: true,
                //     className: "eSignCN_funcDialog_container",
                //     onOk() {
                //         openUrlByBrowser('https://www.foxitsoftware.cn/esign#sales')
                //     }
                // })
                showESignCNDialogAPI({
                    title: "福昕电子签章服务",
                    type: "Dialog",
                    id: "ESIGNCN_IN_SIGN_CONTACT_SALESMAN_TO_CREATE_ORGANIZE",
                    msg: "感谢您对福昕电子签章服务的关注，对内签署需联系销售为您所在企业创建信息。"
                })
            } else if (ERROR_AC_PERMISSION_FAIL === key) {
                // Modal.info({
                //     title: '福昕电子签章服务',
                //     icon: "",
                //     content: <span style={{display: 'flex', alignItems: 'center'}}><ExclamationCircleOutlined style={{fontSize: '22px', color: '#faad14'}}/><span style={{flex: 1, marginLeft: '8px'}}>您尚未被分配对内签署的授权，请联系IT管理员分配授权后重试。</span></span>,
                //     zIndex: 99999,
                //     okText: "确认",
                //     mask: false,
                //     closable: true,
                //     className: "eSignCN_funcDialog_container"
                // })
                showESignCNDialogAPI({
                    title: "福昕电子签章服务",
                    type: "Dialog",
                    id: "ESIGNCN_IN_SIGN_AC_AUTH_LACK",
                    msg: "您尚未被分配对内签署的授权，请联系IT管理员分配授权后重试。"
                })
            } else {
                result.msg = '提示：获取印章列表错误，请刷新页面重试。';
            }
            result.sealsError = true;
            return Promise.resolve(result);
        });
        taskArr[0] = task;
    } else {
        if (orgId && company) {
            const task = checkAuth(true, getStore('userInfo').enterpriseId, getStore('userInfo').token).then(checkAuthRes => {
                if (checkAuthRes < 1) {
                    let error;
                    switch (checkAuthRes) {
                        case -1:
                            error = Promise.reject(ERROR_AC_ORG_PERMISSION_FAIL);
                            break;
                        case -2:
                            error = Promise.reject(ERROR_AC_PERMISSION_FAIL);
                            break;
                        default:
                            error = Promise.reject(ERROR_AC_FAIL);
                    }
                    if (error) {
                        return error;
                    }
                }
                const result = {
                    msg: null,
                    link: null,
                    linkTxt: null,
                    linkTarget: '_blank',
                    seals: [],
                    sealsError: false,
                }
                return getOrgSeals({orgId, accountId}).then(seals => {
                    if (!seals || seals.length < 1) {
                        // Modal.info({
                        //     title: '福昕电子签章服务',
                        //     icon: "",
                        //     content: <span style={{display: 'flex', alignItems: 'center'}}><ExclamationCircleOutlined style={{fontSize: '22px', color: '#faad14'}}/><span style={{flex: 1, marginLeft: '8px'}}>无可用的企业章，请联系企业印章管理员设置并授权。</span></span>,
                        //     zIndex: 99999,
                        //     okText: "确认",
                        //     mask: false,
                        //     closable: true,
                        //     className: "eSignCN_funcDialog_container"
                        // })
                        showESignCNDialogAPI({
                            title: "福昕电子签章服务",
                            type: "Dialog",
                            id: "ESIGNCN_OUT_SIGN_ORG_SEALS_LACK",
                            msg: "无可用的企业章，请联系企业印章管理员设置并授权。"
                        })
                    } else {
                        result.seals = seals;
                    }
                    return result;
                })
            }).catch(key => {
                console.warn('getOrgSeals error', key);
                if (ERROR_EQB_AUTH_EXPIRED === key) {
                    // Modal.confirm({
                    //     title: '福昕电子签章服务',
                    //     icon: "",
                    //     content: <span style={{display: 'flex', alignItems: 'center'}}><ExclamationCircleOutlined style={{fontSize: '22px', color: '#faad14'}}/><span style={{flex: 1, marginLeft: '8px'}}>您的授权过期，请重新完成企业认证/授权。</span></span>,
                    //     zIndex: 99999,
                    //     okText: "认证/授权",
                    //     cancelText: "取消",
                    //     mask: false,
                    //     closable: true,
                    //     className: "eSignCN_funcDialog_container",
                    //     onOk() {
                    //         showESignCNDialogAPI({ title: '企业实名认证', id: 'ESIGNCN_ORG_AUTH', type: 'Window', webUrl: `${window.location.origin}/eSignCN-cert?orgName=${company}&wardType=0`})
                    //     }
                    // })
                    showESignCNDialogAPI({
                        title: "福昕电子签章服务",
                        type: "Dialog",
                        id: "ESIGNCN_ORG_AUTH_CONFIRM",
                        msg: "您的授权过期，请重新完成企业认证/授权。"
                    })
                }
                else if (ERROR_AC_ORG_PERMISSION_FAIL === key) {
                    // Modal.confirm({
                    //     title: '福昕电子签章服务',
                    //     icon: "",
                    //     content: <span style={{display: 'flex', alignItems: 'center'}}><ExclamationCircleOutlined style={{fontSize: '22px', color: '#faad14'}}/><span style={{flex: 1, marginLeft: '8px'}}>您的对外签署订阅已过期或您所在的组织未采购对外签署服务，请联系福昕销售。</span></span>,
                    //     zIndex: 99999,
                    //     okText: "联系销售",
                    //     cancelText: "取消",
                    //     mask: false,
                    //     closable: true,
                    //     className: "eSignCN_funcDialog_container",
                    //     onOk() {
                    //         openUrlByBrowser('https://www.foxitsoftware.cn/esign#sales')
                    //     }
                    // })
                    showESignCNDialogAPI({
                        title: "福昕电子签章服务",
                        type: "Dialog",
                        id: "ESIGNCN_OUT_SIGN_APP_STORE_EXPIRE",
                        msg: "您的对外签署订阅已过期或您所在的组织未采购对外签署服务，请联系福昕销售。"
                    })
                }
                else if (ERROR_EQB_ORG_TOKEN_EXPIRED === key) {
                    // result.msg = '提示：您所在的企业授权过期，请联系管理员重新进行企业认证/授权。';
                    // Modal.info({
                    //     title: '福昕电子签章服务',
                    //     icon: "",
                    //     content: <span style={{display: 'flex', alignItems: 'center'}}><ExclamationCircleOutlined style={{fontSize: '22px', color: '#faad14'}}/><span style={{flex: 1, marginLeft: '8px'}}>您所在的企业授权过期，请联系管理员重新进行企业认证/授权。</span></span>,
                    //     zIndex: 99999,
                    //     okText: "确认",
                    //     mask: false,
                    //     closable: true,
                    //     className: "eSignCN_funcDialog_container"
                    // })
                    showESignCNDialogAPI({
                        title: "福昕电子签章服务",
                        type: "Dialog",
                        id: "ESIGNCN_OUT_SIGN_AC_AUTH_EXPIRE",
                        msg: "您所在的企业授权过期，请联系管理员重新进行企业认证/授权。"
                    })
                }
                else if (ERROR_AC_PERMISSION_FAIL === key) {
                    // Modal.info({
                    //     title: '福昕电子签章服务',
                    //     icon: "",
                    //     content: <span style={{display: 'flex', alignItems: 'center'}}><ExclamationCircleOutlined style={{fontSize: '22px', color: '#faad14'}}/><span style={{flex: 1, marginLeft: '8px'}}>您尚未被分配对外签署的授权，请联系IT管理员分配授权后重试。</span></span>,
                    //     zIndex: 99999,
                    //     okText: "确认",
                    //     mask: false,
                    //     closable: true,
                    //     className: "eSignCN_funcDialog_container"
                    // })
                    showESignCNDialogAPI({
                        title: "福昕电子签章服务",
                        type: "Dialog",
                        id: "ESIGNCN_OUT_SIGN_AC_AUTH_LACK",
                        msg: "您尚未被分配对外签署的授权，请联系IT管理员分配授权后重试。"
                    })
                }
                else {
                    const org = sessionStorage.getItem('esignOrg');
                    let isWaitingOrgInit = false;
                    if (org) {
                        let orgAuthTime = JSON.parse(org).authTime;
                        isWaitingOrgInit = orgAuthTime ? (new Date().getTime() - orgAuthTime) < 1000 * 60 * 10 : false;
                    }
                    // if (getStore('plugin').isWaitingOrgInit) {
                    if (isWaitingOrgInit) {
                        showESignCNDialogAPI({
                            title: "福昕电子签章服务",
                            type: "Dialog",
                            id: "ESIGNCN_OUT_SIGN_AC_AUTH_LACK",
                            msg: "新创建企业授权尚未完成，请稍后再次确认。"
                        })
                    }
                }
                result.sealsError = true;
                return Promise.resolve(result);
            })
            taskArr[1] = task
        }
        const result = {
            msg: null,
            link: null,
            linkTxt: null,
            linkTarget: '_blank',
            seals: [],
            sealsError: false,
        }
        const task = getPersonSeals({accountId}).then(seals => {
            if (!seals || seals.length < 1) {
                result.msg = '提示：无可用的个人章，请在印章管理平台添加。';
            } else {
                result.seals = seals;
            }
            return result;
        }).catch(key => {
            if (ERROR_EQB_AUTH_EXPIRED === key) {
                result.msg = '提示：您的授权过期，请重新完成个人认证。';
                result.link = "/certification";
                result.linkTxt = '点击认证';
                result.linkTarget = '_self';

                // Modal.confirm({
                //     title: '福昕电子签章服务',
                //     icon: "",
                //     content: <span style={{display: 'flex', alignItems: 'center'}}><ExclamationCircleOutlined style={{fontSize: '22px', color: '#faad14'}}/><span style={{flex: 1, marginLeft: '8px'}}>您的授权过期，请重新完成个人认证。</span></span>,
                //     zIndex: 99999,
                //     okText: "认证",
                //     cancelText: "取消",
                //     mask: false,
                //     closable: true,
                //     className: "eSignCN_funcDialog_container",
                //     onOk() {
                //         showESignCNDialogAPI({ title: '个人实名认证', id: 'ESIGNCN_PERSON_AUTH', type: 'Window', webUrl: `${window.location.origin}/eSignCN-cert?state=2&wardType=0`, width: 600, height: 600})
                //     }
                // })
                showESignCNDialogAPI({
                    title: "福昕电子签章服务",
                    type: "Dialog",
                    id: "ESIGNCN_PERSON_AUTH_CONFIRM",
                    msg: "您的授权过期，请重新完成个人认证。"
                })
            } else {
                result.msg = '提示：获取印章列表错误，请刷新页面重试。';
            }
            result.sealsError = true;
            return Promise.resolve(result);
        })
        taskArr[2] = task
    }
    return Promise.all(taskArr)
}

export function requestSealsToStore({company, silent = false, notLoadOutOrgSeal = false}) {
    const wardType = getStore('plugin').wardType;
    const {orgId, accountId} = getStore('userInfo');
    const taskArr = [Promise.resolve(), Promise.resolve(), Promise.resolve()]
    if (wardType === 1) { // 对内印章
        taskArr[0] = getInnerSealList();
   } else {
        if (orgId && company && !notLoadOutOrgSeal) { // 对外企业章
            taskArr[1] = getOrgSeals({orgId, accountId})
        }
        taskArr[2] = getPersonSeals({accountId}); // 对外个人章
    }
    return Promise.all(taskArr).then(([inSeals, outOrgSeals, outPersonSeals]) => {
        setStore('plugin', { ...getStore('plugin'), outOrgSeals, outPersonSeals, inSeals, ...{ sealsError: false } })
        return Promise.resolve();
    }).catch(e => {
        // 在这处理印章接口异常信息
        console.error(e)
        setStore('plugin', { ...getStore('plugin'), ...{ sealsError: true } })
        return Promise.reject();
    })
}

export async function enterPersonCreate () {
    const accountId = getStore('userInfo').accountId
    if(!accountId) {
        console.error('accountId not exist')
    }
    const psnSealCreateUrl = await getCreateUrl({accountId})
    return handleEnterAdminResponse(psnSealCreateUrl, false)
}

async function getCreateUrl({ accountId }) {
   return getPersonCreateUrl({ accountId }).catch(key => {
        if (ERROR_EQB_AUTH_EXPIRED === key) {
            // Modal.confirm({
            //     title: '福昕电子签章服务',
            //     icon: "",
            //     content: <span style={{display: 'flex', alignItems: 'center'}}><ExclamationCircleOutlined style={{fontSize: '22px', color: '#faad14'}}/><span style={{flex: 1, marginLeft: '8px'}}>您的授权已过期，请重新授权。</span></span>,
            //     zIndex: 99999,
            //     okText: "授权",
            //     cancelText: "取消",
            //     mask: false,
            //     closable: true,
            //     className: "eSignCN_funcDialog_container",
            //     onOk() {
            //         // 个人实名认证
            //         showESignCNDialogAPI({ title: '个人实名认证', id: 'ESIGNCN_PERSON_AUTH', type: 'Window', webUrl: `${window.location.origin}/eSignCN-cert?state=2&wardType=0`, width: 600, height: 600})
            //         closeESignCNDialogAPI()
            //     },
            //     onCancel() {
            //         closeESignCNDialogAPI()
            //     }
            // })
            showESignCNDialogAPI({
                title: "福昕电子签章服务",
                type: "Dialog",
                id: "ESIGNCN_PERSON_AUTH_CONFIRM",
                msg: "您的授权过期，请重新完成个人认证。"
            })
        }
    })
}

async function handleEnterAdminResponse(eqbUrl, isOrg) {
    const iWidth = isOrg ? 1273 : 1217;
    const url = `${window.location.origin}/iframe-dialog?targetUrl=${encodeURIComponent(eqbUrl)}&wardType=0`
    showESignCNDialogAPI({ title: '创建个人印章', type: 'Window', id: 'ESIGNCN_PERSON_STAMP_CREATE', webUrl: url, width: iWidth, height: 600 })
}

function _getAuth(){
    const info = getStore('userInfo');
    return binding().then(res => {
        const {person, orgList} = res;
        const enterpriseId = info.enterpriseId;
        let org = {};
        if (!person || person.length < 1) {
            return Promise.resolve({ error: 'PERSON_NOT_AUTH' });
        }
        if (enterpriseId && Array.isArray(orgList) && orgList.length > 0) {
            orgList.reverse().some(item => {
                if (item.tenantId === enterpriseId) {
                    org = item;
                    return true;
                }
                return false;
            })
        }

        setStore('userInfo', {...info, trueName: person.name, accountId: person.accountId, orgName: org.name, orgId: org.orgId});
        setStore('userStatus', org.name ? 3 : 2);
        setStore('esignPerson', person);
        setStore('esignOrg', org);
        sessionStorage.setItem('esignOrg', JSON.stringify(org));
        return Promise.resolve();
    });

}

function htmlspecialchars(value) {
    // HTML 实体编码函数，将特殊字符转换为对应的 HTML 实体编码
    return value
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
}

export function getQueryVariable(variable) {
    let query = window.location.search.substring(1);
    let vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split('=');
        let paramName = decodeURIComponent(pair[0]);
        let paramValue = decodeURIComponent(pair[1]);
        if (paramName === variable) {
            let filteredValue = htmlspecialchars(paramValue);
            return filteredValue;
        }
    }
    return null;
}

export function getDomainFromURL(url) {
    let domain = '';
    try {
        const urlObject = new URL(url); // 创建 URL 对象
        domain = urlObject.hostname; // 获取主机名
    } catch (error) {
        console.error('Invalid URL:', error);
    }
    return domain;
}

export function urlSecurityFilter(url) {
    const domain = getDomainFromURL(url);
    let pass = false;
    pass = E_QIAN_BAO_DOMAINS.some(eqbDomain => domain.indexOf(eqbDomain));
    const pcDomain = getStore('configUrls').eSignCNUrl;
    if (domain.indexOf(pcDomain)) {
        pass = true;
    }
    if (pass) {
        return url;
    } else {
        return ''
    }
}

export function globalFn_closeDialog() {
    window.eSignCN_closeDialogCallback = () => {
        closeESignCNDialogAPI()
    }
}

export async function globalFn_getPdfInfo() {
    const assignInfo = await getAssignInfoForPhantom()
    return formatPdfInfo(assignInfo).then(pdfInfo => {
        if (pdfInfo) {
            pdfInfo.initiator = assignInfo.initiator;
            setStore('plugin', { ...getStore('plugin'), pdfInfo })
        } else {
            setStore('plugin', { ...getStore('plugin'), pdfInfo: null })
        }
    })
}

function  globalFnBind() {
    const personAuthFn = () => showESignCNDialogAPI({ title: '个人实名认证', id: 'ESIGNCN_PERSON_AUTH', type: 'Window', webUrl: `${window.location.origin}/eSignCN-cert?state=2&wardType=0`, width: 600, height: 600})

    if (getStore('userInfo').enterpriseName) {
        const orgAuthFn = () => showESignCNDialogAPI({ title: '企业实名认证', id: 'ESIGNCN_ORG_AUTH', type: 'Window', webUrl: `${window.location.origin}/eSignCN-cert?orgName=${getStore('userInfo').enterpriseName}&wardType=0`})
        window.eSignCN_orgAuthFn = orgAuthFn;
    }

    const openSaleLink = () => openUrlByBrowser('https://www.foxitsoftware.cn/esign#sales');

    const loginSuccessCallback = () => {
        window.location.reload();
    }

    const closeDialogByPhantom = () => closeESignCNDialogAPI()

    window.eSignCN_personAuthFn = personAuthFn;
    window.eSignCN_openSaleLink = openSaleLink;
    window.eSignCN_closeDialogCallback = closeDialogByPhantom;
    window.eSignCN_loginSuccessCallback = loginSuccessCallback;
}

export function inIframeEnvFn() {
    const inIframeEnv = getQueryVariable('inIframeEnv');
    if (inIframeEnv) {
        // 获取当前页面的URL，去除其search中的inIframeEnv参数
        const url = window.location.href;
        const urlObj = new URL(url);
        urlObj.searchParams.delete('inIframeEnv');
        // 发送postMessage消息，通知父页面关闭iframe
        const message = {
            data: {
                type: 'switchToParentEnv',
                targetUrl: urlObj.href
            }
        }
        window.parent.postMessage(message, "*");
    }
    return inIframeEnv;
}

export const getOtelSpanName = (span) => {
    let env;
    if (inOnline()) {
        env = 'online'
    } else if (inPhantom()) {
        env = 'phantom'
    }
    return `esigncn-${env}-${span}`
}

export const WARD_TYPE = {
    in: 'in',
    out: 'out',
    unknow: 'unknow'
}
export const getWardType = (wardType) => { // 不同接口返回的wardType类型不一致
    if (wardType + '' === '0') {
        return 'out'
    } else if (wardType + '' === '1') {
        return 'in'
    } else {
        return 'unknown'
    }
}

export const ORG_PERMISSION = {
    SIGN_RECORD: 1,
    SEAL_RECORD: 2,
}
export const ESIGN_RECORD_TABS = {
    DOC_RECORD: 'doc-record',
    SEAL_RECORD: 'seal-record',
    ORG_DOC_RECORD: 'org-doc-record',
    ORG_SEAL_RECORD: 'org-seal-record',
}

export function sleep (t) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve()
        }, t)
    })
}

export const FLOW_SIGN_ORDERED_TYPE = {
    ALL_ORDERED: 1,
    PART_ORDERED: 2,
    NON_ORDERED: 3
}

export const getFlowSignOrderedType = (receipts) => {
    const signOrders = receipts.map(i => i.sign_order);
    const formatSignOrders = Array.from(new Set(signOrders));
    if (formatSignOrders.length === 1) {
        return FLOW_SIGN_ORDERED_TYPE.NON_ORDERED;
    } else if (formatSignOrders.length === signOrders.length) {
        return FLOW_SIGN_ORDERED_TYPE.ALL_ORDERED;
    } else {
        return FLOW_SIGN_ORDERED_TYPE.PART_ORDERED;
    }
}

export const searchFlowManFn = (params) => {
    if (params.type === 'company') {
       return searchFlowMan(params)
        .catch(() =>{
            return [];
        })
        .then(res1 => {
            return Promise.all([res1, searchOrgNameForPhantom(params)])
        })
        .then(arr => {
            console.log('arr', arr)
            const [res1, res2] = arr;
            let res = [];
            if (res1) {
                res = res.concat(res1);
            }
            if (res2) {
                res = res.concat(res2.map(item => {
                    item.company = item.name;
                    item.name = "";
                    item.companySource = "threePart"
                    return item;
                }));
            }
            return res;
        }).catch(e => {
            console.log(e);
        });

    } else {
        return searchFlowMan(params);
    }

}

function initMountOnWindow() {
    globalFn_closeDialog();
}

async function loadTheme () {
    try {
        const path = window.location.pathname;
        if(!SUPPORT_DARK_THEME_ROUTES.includes(path)) return;
        const dark = getQueryVariable('theme') === 'dark';
        // 动态加载暗黑主题 CSS
        if (dark) {
            document.documentElement.setAttribute('data-theme', 'dark');
            await import('antd/dist/antd.dark.css');
            await import('./theme.css')
            // 移除默认主题（可选）
            const defaultTheme = document.querySelector('link[href*="antd.default.css"]');
            if (defaultTheme) defaultTheme.remove();
        } else {
            document.documentElement.removeAttribute('data-theme');
        }
    } catch (error) {
        console.error('主题加载失败:', error);
    }
};