import {mockSwitch, PHANTOM_UA_KEY} from "./config";
import {
    getAssignInfoForPhantomMockData,
    getAuditTrailMockData,
    getSigVerifyInfo,
    getUserInfoMockData
} from "./MockData";
import {
    fetchAuditTrailInfo,
    fetchSigVerifyInfoForPhantom,
    getUserInfoAPIFromPhantom,
    notifyAuthResultForPhantom,
    logoutForPhantom, openLoginWindowOfPhantom, resizeDialogForPhantom,
    getAssignInfoForPhantomAPI,
    openFileByUrlForPhantom,
    showShareOnlineDocDialogForPhantom,
    chooseFileForSignFLowForPhantom, invalidSignFlowForPhantom, updateUserInfoForPhantom
} from "../../request/phantom";
import msgTool from "../../request/online";
import {getQueryVariable} from "./service";
import {getStore} from "../../store";
import {getRatio, inPhantom} from "./utils/util";
import {doLogout4PublicPage} from "../../utils";

function constructUserInfoData(userInfo) {
    return {
        token: userInfo.Token,
        enterpriseId: userInfo.EnterpriseId,
        enterpriseName: userInfo.EnterpriseName,
        pluginVersion: userInfo.pluginVersion,
    }
}

export async function getUserInfoFromContainer() {
    let userInfo = {}
    if (mockSwitch && !(getQueryVariable('origin') === 'ONLINE_CONTAINER')) {
        userInfo = getUserInfoMockData();
    } else {
        if (getQueryVariable('origin') === 'ONLINE_CONTAINER') {
            userInfo = await msgTool.getUserInfoAPIFromOnline()
        }
        if (window.navigator.userAgent.indexOf(PHANTOM_UA_KEY) > -1) {
            userInfo = await getUserInfoAPIFromPhantom();
        }
    }
    // let userInfo = getUserInfoMockData();
    return constructUserInfoData(userInfo);
}

export function fetchAuditTrail(docHash) {
    if (mockSwitch) {
        return getAuditTrailMockData();
    } else {
        const inOnline = getQueryVariable('origin') === "ONLINE_CONTAINER";
        if (inOnline) {
          return msgTool.fetchAuditTrailInfoFromOnline({ docHash })
        }
        if (inPhantom())
        return fetchAuditTrailInfo();
    }
}

export function fetchSigVerifyData(fileSizeOfSignedVersion) {
    if (mockSwitch) {
        return getSigVerifyInfo();
    } else {
        return fetchSigVerifyInfoForPhantom(fileSizeOfSignedVersion);
    }
}

export function getAssignInfoForPhantom() {
    if (mockSwitch) {
        return getAssignInfoForPhantomMockData()
    } else {
        return getAssignInfoForPhantomAPI()
    }
}

export function openFileByPlugin({fileUrl, fileName}) {
    if (getStore('plugin').inPhantom) {
        return openFileByUrlForPhantom({ fileUrl, fileName })
    } else {
        return msgTool.openFileUrlFromOnline({ fileUrl, fileName })
    }
}

// export function getStampDropDownInitParams() {
//     if (mockSwitch) {
//         return getStampDropDownInitParamsMockData()
//     } else {
//         return getStampDropDownInitParamsAPI()
//     }
// }

export function showShareOnlineDocDialog(params) {
    if (inPhantom()) {
        return showShareOnlineDocDialogForPhantom(params)
    } else {
        return msgTool.showShareOnlineDocDialogFromOnline(params)
    }
}

// 调用online方法完成作废流程发起
export function invalid2Online(data) {
    if (inPhantom()) {
        const base64 = window.btoa(String.fromCharCode(...new Uint8Array(data.buffer)))
        delete data.buffer;
        data.base64 = base64;
        return invalidSignFlowForPhantom(data);
    } else {
        return msgTool.invalid2Online(data);
    }
}


export function chooseFileForFlowSign() {
    if (inPhantom()) {
        return chooseFileForSignFLowForPhantom()
    } else {
        return msgTool.showESignCNDialog({ id: 'SIGN_RECORD_OPEN_FILE_CONFIRM', type: 'Dialog', title: '福昕PDF编辑器', msg: '确认从本地选择文档发起签署吗？'})
    }
}

export function notifyAuthResult(params) {
    console.log('notifyAuthResult', params)
    if (inPhantom()) {
        return notifyAuthResultForPhantom(params)
    } else {
        return Promise.resolve();
    }
}

export function logout() {
    if (inPhantom()) {
        return logoutForPhantom()
    } else {
        return doLogout4PublicPage(getStore('userInfo').token);
    }
}

export function reLogin() {
    if (inPhantom()) {
        return logoutForPhantom().then(() => {
            return openLoginWindowOfPhantom();
        })
    } else {
        return doLogout4PublicPage(getStore('userInfo').token);
    }
}

export function loginByPublicPageEnv() {
    if (inPhantom()) {
        return openLoginWindowOfPhantom();
    } else {
        return msgTool.loginFromOnline();
    }
}

export function resizeDialog(params) {
    const dpr = getRatio();
    params.width = params.width * dpr;
    params.height = params.height * dpr;
    if (inPhantom()) {
        return resizeDialogForPhantom(params)
    } else {
        return Promise.resolve();
    }
}

export function updateUserInfoForContainer(params) {
    if (inPhantom()) {
        return updateUserInfoForPhantom(params)
    } else {
        return Promise.resolve();
    }
}