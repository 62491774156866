import styles from "../index.module.less";
import {InboxOutlined} from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import {Button, Checkbox, Upload} from "antd";
import {errorMessage} from "../../components/Message";
import {useEffect, useRef, useState} from "react";
import {getBase64, getBlackBase64} from "../../../utils/";
import { useTranslation } from "react-i18next";


export function ExtUpload (props) {
    const {setUploadClear, hasImg} = props;
    const { t } = useTranslation('sealPlugin');
    const clearData = () => {
        setUploadClear(new Date().getTime());
    }
    return (
        <Button hidden={!hasImg} type={"link"}  onClick={clearData}>{t('button.replace')}</Button>
    )
}

export default function CreateUpload (props) {
    const { t } = useTranslation('sealPlugin');
    const [isBlack, setIsBlack] = useState(false);
    const {setUpload, uploadClear} = props
    const [imgUrl, setImgUrl] = useState();
    const [file, setFile] = useState(undefined);

    const imgRef = useRef();

    useEffect(() => {
        if (!file) {
            return
        }
        if (isBlack) {
            getBlackBase64(file).then(base64 => {
                setImgUrl(base64);
                setUpload(base64);
            });
        } else {
            getBase64(file).then(base64 => {
                setImgUrl(base64);
                setUpload(base64);
            });
        }
    }, [file, isBlack]);

    useEffect(() => {
        if (uploadClear) {
            imgRef.current.click();
        }
    }, [uploadClear]);

    const uploadProps = {
        name: 'file',
        multiple: false,
        accept: ".png, .jpg, .jpeg",

        beforeUpload: file => {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                errorMessage(t('message.imageTypeError'));
                return Upload.LIST_IGNORE;
            }

            const isLt200K = file.size / 1024  < 200;

            if (!isLt200K) {
                errorMessage(t('message.imageSizeLimit'));
                return Upload.LIST_IGNORE;
            }
            setFile(file)
            return Upload.LIST_IGNORE;
        },


        onDrop(e) {
            // console.log('Dropped files', e.dataTransfer.files);
        },
    };

    return (<>
            <div className={styles.tabContainer + ' ' + styles.tabUpload} >
                <Dragger {...uploadProps}  >
                    {imgUrl ? (
                        <img src={imgUrl} alt="" ref={imgRef} />
                    ) : (
                        <div className={styles.upload}>
                            <p>
                                <InboxOutlined />
                            </p>
                            <p>{t('tips.dragOrClickToUpload')}</p>
                            <p style={{color: '#ccc'}}>
                                {t('tips.uploadLimit200kb')}
                            </p>
                        </div>
                    )}

                </Dragger>
            </div>

            <div style={{marginTop: 20, userSelect: 'none'}}>
                <Checkbox style={{color:"var(--text-color)"}} checked={isBlack} onChange={e => setIsBlack(e.target.checked)}>
                    {t('tips.convertToBlackAndWhite')}</Checkbox>
            </div>
    </>

    )
}


