import React, {useEffect, useState} from "react";
import {
    getAuthUrl,
} from "../../../request/esign";
import styles from "../index.module.less";
import icon from "../../../assets/images/authPlugin/esignbao.png";
import {Button, Skeleton} from "antd";
import { useTranslation } from 'react-i18next';

export default function PcViewer(props) {
    const {flowId, type} = props.querys;
    return <>
        {type === 10 &&  <Result {...props} flowId={flowId}></Result>}
        {type !== 10 &&  props.stat !== 5 && <Agree {...props}></Agree>}
        {type === 3 && props.stat === 5 && <ResultUI stat={props.stat} sendMsg={props.sendMsg}></ResultUI>}
    </>
}

function Agree(props) {
    const { t,i18n } = useTranslation('authPlugin');
    const {token, authParams, sendMsg} = props;
    const [btnLoading, setBtnLoading] = useState(false);
    const [showUI, setShowUI] = useState(false);

    useEffect(() => {
        sendMsg({type: 'resize', height: '480px'});
        if (authParams.orgName) {
            _getUrl({token, authParams, sendMsg, i18n});
        } else {
            setShowUI(true);
        }
    }, [token, sendMsg, authParams]);


    const toEsignbao = () =>{
        setBtnLoading(true);
        _getUrl({token, authParams, sendMsg, i18n});
    }

    return showUI && (
        <div className={styles.agree} style={{margin: 0, padding:0}}>
            <div className={styles.head}>
                <div className={styles.title} style={{width: '50%'}}>
                    {t('certAuth.pc.agree.title')}
                </div>
                <img src={icon} alt={"icon"}  height="59" />
            </div>
            <div className={styles.box}>
                {t('certAuth.pc.agree.content1')}
                <br/><br/>
                {t('certAuth.pc.agree.content2')}
                <br/><br/>
                <div dangerouslySetInnerHTML={{__html: t('certAuth.pc.agree.content3')}} ></div>
            </div>
            <div className={styles.buttons}>
                <Button loading={btnLoading} onClick={toEsignbao} type={"primary"} style={{width: '125px'}}>{t('certAuth.pc.agree.agreeBtn')}</Button>
                <Button key="back" onClick={() => sendMsg({type: 'close'})} style={{width: '90px', marginLeft: '15px'}}>{t('certAuth.pc.agree.cancelBtn')}</Button>
            </div>
        </div>
    )
}

function _getUrl({authParams, token, sendMsg, i18n}){
    getAuthUrl(authParams, token).then(url => {
        if (url) {
            sendMsg({type: 'resize', width: '1100px'});
            const lang = i18n.language === 'zh' || i18n.language === 'zh-CN' ? 'zh-CN':'en-US';
            url += `&lang=${lang}`;
            window.location.replace(url);
        }
    });
}

function Result(props) {
    const { t } = useTranslation('authPlugin');
    const {token, flowId, enterpriseId, email, mobile, authParams, sendMsg, stat, seller, initFn} = props;
    const [info, setInfo] = useState({});
    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        sendMsg({type: 'resize', height: '250px'});
        setIsLoading(true);
        initFn({token, flowId, authParams, enterpriseId, email, mobile, stat}).then(info => {
            if (!info.summary) {
                info.summary = t('certAuth.pc.result.summary', {action: t(stat === 4 ? 'certAuth.pc.result.tryNow' : 'certAuth.pc.result.close')});
            }
            setInfo(info);
            setSuccess(!info.isError);
        }).catch(e => {
            setInfo({
                title: t('certAuth.pc.result.authFailed'),
                summary: t('certAuth.pc.result.failedSummary', {seller})
            });
            setSuccess(false);
            throw e;
        }).finally(() => {
            setIsLoading(false);
        });
    }, [sendMsg, token, flowId, authParams, enterpriseId, email, mobile, initFn, seller, stat]);


    return <ResultUI stat={stat} isLoading={isLoading} success={success} info={info} sendMsg={sendMsg}></ResultUI>
}


function ResultUI(props) {
    const { t } = useTranslation('authPlugin');
    let {isLoading= true, stat, success = false, info, sendMsg} = props;
    sendMsg({type: 'resize', height: '250px'});
    if (stat === 5) {
        isLoading = false;
        success = true;
        info = {
            title: t('certAuth.pc.result.personalAuthComplete'),
            summary: t('certAuth.pc.result.summary', {action: t('certAuth.pc.result.tryNow')})
        }
    }

    const closeFn = (setName = false) => {
        if (stat > 3) {
            sendMsg({type:'close', success});
        } else {
            sendMsg({type:'close', reload: success, setName, fc: 1});
        }
    }
    const type = +JSON.parse(sessionStorage.getItem("authPluginParams") || '{}').type;
    return (
        <>
            <Skeleton active loading={isLoading}  className={styles.skeleton}>
                <div className={styles.result}>
                    <div className={styles.icon}>
                        <i className={styles.img + ' ' + (stat === 2 || stat === 3 ? styles.org : styles.cert ) }></i>
                        <p className={styles.stat + ' ' + (success ? styles.statOk : (info.title === t('certAuth.pc.result.companyCreating') ? '' : styles.statErr))}>                            <i className={styles.img + ' ' + (success ? styles.ok : (info.title === t('result.companyCreating') ? styles.waiting : styles.error))}></i></p>
                    </div>

                    <div className={styles.container}>
                        <div className={styles.title} dangerouslySetInnerHTML={{__html: info.title}}></div>
                        <div className={styles.tips} dangerouslySetInnerHTML={{__html: info.summary}}></div>
                    </div>
                </div>
                <div className={styles.buttons}>
                    {type === 1 && stat === 1 && success && <Button type="primary" key="orgIn" onClick={() => closeFn(true) }
                            style={{width: '120px'}}>{t('certAuth.pc.result.enterpriseAuth')}</Button>}
                    <Button type={stat > 3 ? "primary": "default"} key="back" onClick={() => closeFn() }
                            style={{width: '90px', marginLeft: '10px'}}>{t(stat > 3 ? 'certAuth.pc.result.tryNow' : 'certAuth.pc.result.close')}</Button>
                </div>
            </Skeleton>
        </>
    );
}
