import styles from "../index.module.less";
import React, {Fragment} from "react";
import {Button, Skeleton} from "antd";
import {ExclamationCircleFilled} from "@ant-design/icons";
import { useTranslation } from 'react-i18next';

export default function PcViewer(props) {
    const {type, email, mobile} = props.querys;
    const {isLoading, tips, isError, codes, mobileFn, sendMsg, certFn, addOrgFn} = props;

    const { t } = useTranslation('authPlugin');
    let title = t('main.pc.title');
    let summary = t('main.pc.summary');
    const summary2 = type === 1 ? '' : t('main.pc.summary2');
    let btns = [];

    const closeFn = (data) => {
        if (data) {
            const {relogin, setName} = data;
            if (relogin) {
                sendMsg({relogin, type: 'close'});
            }
            else if (setName) {
                sendMsg({setName, type: 'close'});
            }
        } else {
            sendMsg({type: 'close'});
        }
    }

    if (codes.length > 0) {
        codes.forEach(code => {
            let img, text, handle;
            // const seller = `<a href="https://www.foxitsoftware.cn/esign#sales" rel="noreferrer" target='_blank'>联系销售</a>`;
            switch (code) {
                case -1:
                    title = t('main.pc.error.serviceExpired');
                    summary = t('main.pc.error.serviceExpired');
                    img = 'error';
                    handle = closeFn;
                    break;
                case -2:
                    title = t('main.pc.error.noAuth');
                    summary = summary2;
                    img = 'error';
                    handle = closeFn;
                    break;
                case -15:
                    title = t('main.pc.error.authExpired');
                    summary = summary2;
                    img = 'error';
                    handle = closeFn;
                    break;
                case -4:
                case -11:
                case -12: {
                    title = t(code === -4 ? 'main.pc.error.nameNotMatch' : 'main.pc.error.orgNameNotMatch');
                    summary = summary2;
                    img = 'error';
                    handle = closeFn;
                    break;
                }
                case -16:
                    title = t('main.pc.error.duplicateOrgName');
                    summary = summary2;
                    img = 'error';
                    handle = closeFn;
                    break;
                case -6:
                case -8: {
                    title = t(code === -6 ? 'main.pc.error.emailNotMatch' : 'main.pc.error.mobileNotMatch');
                    summary = `${t('main.pc.error.' + (code === -6 ? 'emailNotMatch' : 'mobileNotMatch'))}<br/>${summary2}`;
                    img = 'relogin';
                    text = t('main.pc.buttons.relogin');
                    handle = () => closeFn({relogin: true});
                    break;
                }
                case -10:
                    title = t('main.pc.error.requireEnterprise');
                    summary = `${t('main.pc.error.requireEnterprise')}<br/>${summary2}`;
                    img = 'relogin';
                    text = t('main.pc.buttons.relogin');
                    handle = () => closeFn({relogin: true});
                    break;
                case -17:
                    title = t('main.pc.error.noUsername');
                    summary = `${t('main.pc.error.noUsername')}<br/>${summary2}`;
                    img = 'p-auth';
                    text = t('main.pc.buttons.setUsername');
                    handle = () => closeFn({setName: true});
                    break;
                case -9:
                    img = 'o-auth';
                    text = t('main.pc.buttons.orgAuth');
                    handle = () => certFn(2);
                    break;
                case -5:
                    img = 'email';
                    text = t('main.pc.buttons.completeEmail');
                    handle = mobileFn;
                    break;
                case -7:
                    img = 'mobile';
                    text = t('main.pc.buttons.completeMobile');
                    handle = mobileFn;
                    break;
                case -13:
                    img = 'o-auth';
                    text = t('main.pc.buttons.joinOrg');
                    handle = addOrgFn;
                    break;
                case -14:
                    title = t('main.pc.title');
                    summary = t('main.pc.summary');
                    img = 'o-auth';
                    text = t('main.pc.buttons.orgRealName');
                    handle = () => certFn(3);
                    break;
                case -3:
                    img = 'p-auth';
                    text = t('main.pc.buttons.personalAuth');
                    handle = () => certFn(1);
                    break;
                default:
                    break;
            }
            if (img) {
                btns.push({img, text, handle});
            }
        });
    }

    return (
        <>
            {isLoading && <div className={styles.loadTxt}>
                {t('main.pc.loading')}<p className={styles.tips}>{tips}</p>
            </div>}
            {isError && !isLoading && <div className={styles.errorTxt}>
                <ExclamationCircleFilled /> &emsp;{t('main.pc.error.verifyError')}</div>}
            {!isError && <Skeleton active loading={isLoading}  className={styles.skeleton}>
                <div className={styles.container}>
                    <h1 className={styles.title} dangerouslySetInnerHTML={{__html: title}}></h1>
                    <div className={styles.summary} >{summary && <i className={styles.hint}></i>}
                        <span dangerouslySetInnerHTML={{__html: summary}}></span>
                    </div>
                    <div className={styles.btns}>
                        {btns.map((btn, index) => {
                            return (
                                <Fragment key={index}>
                                    <div  className={index !== 0 ? styles.btn + ' ' + styles.disabled : styles.btn}
                                          onClick={() => index === 0 && btn.handle()}
                                    >
                                        <i className={styles['icon'] + ' ' + styles[btn.img]}></i>
                                        <p className={styles.text}>{btn.text}</p>
                                    </div>
                                    {
                                        index !== btns.length - 1 && <i className={styles.disabled + ' ' + styles.arrow}></i>
                                    }
                                </Fragment>
                            )
                        })}
                    </div>
                </div>
                <div className={styles.footer}>
                    <Button key="back" onClick={() => closeFn()} style={{width: '90px'}}>{t('main.pc.buttons.close')}</Button>
                </div>
            </Skeleton>}
        </>
    )
}
