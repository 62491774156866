import React, {useEffect, useState} from "react";
import {getOrgAdminUrl, getPersonAdminUrl, getPersonCreateUrl} from "../../request/esign";
import {sendMsg} from "./index";
import {
    ERROR_AC_ORG_PERMISSION_FAIL, ERROR_AC_PERMISSION_FAIL,
    ERROR_EQB_AUTH_EXPIRED,
    ERROR_EQB_ORG_TOKEN_EXPIRED,
    ERROR_EQB_SEAL_PERMISSION_FAIL
} from "../../request/error_container";
import styles from "./index.module.less";
import {CloseCircleFilled, SyncOutlined} from "@ant-design/icons";
import ApplyOrgSeals from "./ApplyOrgSeals";
import { useTranslation } from 'react-i18next';

export default function OutPanel({isCreated, isAdmin, orgId, accountId, token, enterpriseId, trueName, hasTab}) {
    const [url, setUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const msg = React.useRef(null);
    const { t, i18n } = useTranslation('sealPlugin');

    useEffect(() => {
        setLoading(true);
        let request;
        if (!hasTab) {
            sendMsg({type: 'resize', width: '890px', height: '606px'});
        }
        if (isCreated) {
            if (isCreated === 1) {
                request = getPersonCreateUrl({token, accountId});
            } else {
                request = getOrgAdminUrl({token,orgId});
            }
        } else {
            request = orgId ? getOrgAdminUrl({token,orgId}) : getPersonAdminUrl({token, accountId});
        }
        request.then(url => {
            msg.current = null;
            const lang = i18n.language === 'zh' || i18n.language === 'zh-CN' ? 'zh-CN':'en-US';
            url += `&lang=${lang}`;
            setUrl(url);

        }).catch(key => {
            setUrl('');
            const result = {};
            if (key === ERROR_EQB_SEAL_PERMISSION_FAIL) {
                console.warn('seal permission fail', isAdmin);
                if (isAdmin) {
                    result.txt = t('outPanel.noPermission');
                    msg.current = result;
                } else {
                    msg.current = null;
                }
            } else {
                 if (ERROR_EQB_AUTH_EXPIRED === key) {
                    if (orgId) {
                        result.txt = t('outPanel.authExpired');
                    } else {
                        result.txt = t('outPanel.personalAuthExpired');
                    }
                }
                else if (ERROR_EQB_ORG_TOKEN_EXPIRED === key) {
                    result.txt = t('outPanel.orgAuthExpired');
                }
                else if (ERROR_AC_ORG_PERMISSION_FAIL === key) {
                    result.txt = t('outPanel.subscriptionExpired');
                    result.link = 'https://www.foxitsoftware.cn/esign#sales';
                    result.linkTxt = t('outPanel.contactSales');
                }
                else if (ERROR_AC_PERMISSION_FAIL === key) {
                    result.txt = t('outPanel.noSignPermission');
                } else if(ERROR_EQB_SEAL_PERMISSION_FAIL === key) {
                    result.txt = t('outPanel.noSealPermission');
                } else {
                    result.txt = t('outPanel.getLinkFailed');
                }
                msg.current = result;
            }
        }).finally(() => {
            setLoading(false);
        });

    }, [orgId, accountId, token, isCreated, hasTab, t]);

    return (
        <>
            {loading && <div className={styles.loading}><SyncOutlined spin/>&emsp;{t('outPanel.loading')}</div>}
            {!loading && url && <iframe title="eSignCn" src={url} className={styles.iframe} height='600px'></iframe>}
            {!loading && !url && msg.current && <ErrorTip {...msg.current} />}
            {!loading && !url && !msg.current && <ApplyOrgSeals trueName={trueName} enterpriseId={enterpriseId} token={token} accountId={accountId} orgId={orgId} />}
        </>
    );
}

function ErrorTip({txt, link, linkTxt}) {
    return (
        <div className={styles.error}>
            <CloseCircleFilled/> &emsp;{txt}
            {link && linkTxt && <a href={link} target="_blank" rel="noreferrer">{linkTxt}</a>}
        </div>
    );
}