import { Component } from "react";
import { Modal, DatePicker, Button } from "antd";
import { changeDeadline } from "@/request/esign";
import { errorMessage, successMessage } from "@/pages/components/Message";
import OtelService from "@/utils/otel/service";
import { getOtelSpanName } from "@/pages/PublicPage/service";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
dayjs.extend(weekday);
dayjs.extend(localeData);

const otelService = new OtelService();

const disabledDate = (current) => {
  return current && current.endOf('day') < dayjs().endOf('day');
};
class DeadlineDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      value: undefined,
      currProcessId: "",
    };
  }
  openDeadlineModal({ currProcessId, value }) {
    this.setState({
      visible: true,
      value:
        value && value !== "0"
          ? dayjs(new Date(Number(`${value}000`)))
          : undefined,
      currProcessId,
    });
  }
  changeValue(value) {
    this.setState({
      value,
    });
  }
  async confirm() {
    const processId = this.state.currProcessId;
    let value = "0";
    if (this.state.value) {
      value = dayjs(this.state.value.format("YYYY-MM-DD 23:59:59"))
      value = value.unix()
    }
    if (!processId) {
      return;
    }
    changeDeadline({ processId, expireTime: value })
      .then(() => {
        otelService
          .startCommonSpan(getOtelSpanName("request-signRecord-deadlineTask"))
          .then(({ end }) => {
            end && end();
          });

        this.setState({
          visible: false,
          value: undefined,
          currProcessId: "",
        });
        successMessage("截止日期修改成功");
        this.props.success();
      })
      .catch(() => {
        errorMessage("截止日期修改失败，请重试");
      });
  }
  render() {
    const { visible, value } = this.state;
    return (
      <Modal open={visible} width={380} footer={null} onCancel={() => this.setState({ visible: false, value:undefined, currProcessId: "" })}>
        <div>
          <div
            className="title"
            style={{ marginBottom: "20px" }}
          >
            <span style={{ fontSize: "18px" }}>签署截止日期</span>
          </div>
          <div>
            <DatePicker
              size="middle"
              format={"YYYY-MM-DD 23:59:59"}
              disabledDate={disabledDate}
              style={{ width: "100%" }}
              value={value}
              onChange={(e) => this.changeValue(e)}
            />
          </div>
          <div
            style={{
              padding: "10px",
              fontSize: "12px",
              marginTop: "10px",
            }}
          >
            <div>1.如果日期为空，则认为无签署截止日期</div>
            <div>2.系统会在到期前1天，提醒各参与人</div>
          </div>
          <div style={{ textAlign: "right", marginTop: "30px" }}>
            <Button
              onClick={() =>
                this.setState({ visible: false, currProcessId: "" })
              }
              style={{
                height: "28px",
                padding: "0 25px",
                marginRight: "10px",
              }}
              shape="round"
            >
              取消
            </Button>
            <Button
              onClick={() => this.confirm()}
              style={{
                height: "28px",
                padding: "0 25px",
                background: "#982E91",
                border: "#982E91",
                color: "#fff",
              }}
              type="primary"
              shape="round"
            >
              确认
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}
export default DeadlineDialog;
