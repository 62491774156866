import styles from './index.module.less';
import {useEffect, useRef, useState} from "react";
import ValidatorMain from "./main";
import ValidatorResult from "./validResult";
import {initWorker} from  '../../../../public/websdk/worker/dist';
import {file2Buffer} from "../utils/util";
import { SDK_CODE } from '@/pages/PublicPage/constant';
import {hideMessage, loadingMessage} from "../../components/Message";
import {useTranslation} from "react-i18next";


export default function CommonSignValidator() {

    const { t } = useTranslation('commonSignValidator');

    const [showValidResult, setShowValidResult] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validInfo, setValidInfo] = useState();
    const [workerInitSuccess, setWorkerInitSuccess] = useState(false);
    const [failReason, setFailReason] = useState('');

    const FAIL_REASON = {
        DEFAULT: t('commonSignValidator:documentValidationFailed'),
        TYPE_ERROR: t('commonSignValidator:onlyPdfSupported'),
        SIZE_ERROR: t('commonSignValidator:chooseFileUnder50M'),
        NO_SIG: t('commonSignValidator:noSignedSignatureFieldFound'),
        ENCRYPTED: t('commonSignValidator:encryptedDocNotSupported'),
    }

    const docRef = useRef();
    const workerRef = useRef();

    function uploadDoc(workerFetch, buffer) {
        return workerFetch.uploadDoc({buffer}).then(res => {
            if (!res || res.code !== SDK_CODE.SUCCESS) {
                return Promise.reject(res.code || '')
            } else {
                return Promise.resolve();
            }
        })
    }

    function validatorDoc(workerFetch) {
        return workerFetch.validatorDoc().then(res => {
            if(!res || res.code !== SDK_CODE.SUCCESS || !res.data) {
                if (res.code === SDK_CODE.NO_SIG) {
                    return Promise.reject(FAIL_REASON.NO_SIG)
                }
                return Promise.reject()
            } else {
                const data = res.data;
                if (!data.docInfo || !data.sigs || data.sigs.length < 1) {
                    setFailReason(FAIL_REASON.NO_SIG);
                    return Promise.reject(FAIL_REASON.NO_SIG);
                } else {
                    setValidInfo(res.data);
                    setShowValidResult(true);
                }
            }
        })
    }


    function setDocFn(file) {
        setFailReason('');
        docRef.current = file;
        if (!workerRef.current || !workerInitSuccess) return;
        setLoading(true);
        return file2Buffer(file).then((buffer) => {
            return uploadDoc(workerRef.current, buffer);
        })
        .then(() => {
            return validatorDoc(workerRef.current);
        })
        .catch(err => {
            if (!err) {
                setFailReason(FAIL_REASON.DEFAULT);
                return;
            }
            if (err === SDK_CODE.UPLOAD_DOC_FAIL_ENCRYPTED) {
               setFailReason(FAIL_REASON.ENCRYPTED);
            } else if (err === SDK_CODE.NO_SIG) {
               setFailReason(FAIL_REASON.NO_SIG);
            } else if(err === SDK_CODE.UPLOAD_DOC_FAIL_UN_KNOW) {
               setFailReason(FAIL_REASON.DEFAULT);
            } else {
               setFailReason(err);
            }
        })
        .finally(() => {
            setLoading(false);
        })
    }
    function clearDocFn(from = 'main-view') {
        docRef.current = null;
        if (from !== 'result-view') {
            setShowValidResult(false);
        }
    }

    function initWorkerFn() {
       return initWorker();
    }

    useEffect(() => {
        document.title = t('foxitDocumentValidationService')
        document.body.setAttribute('data-env', 'common-sign-validator');

        loadingMessage(t('initializingValidationEngine'));
        const workerFetch = initWorkerFn();
        workerRef.current = workerFetch;
        workerFetch.initEngine({
            enginePath: window.location.origin + '/websdk/wasm/'
        }).then(res => {
            if(res && res.code === SDK_CODE.SUCCESS) {
                setWorkerInitSuccess(true);
            } else {
                setFailReason(t('systemError'))
            }
        }).finally(() => {
            hideMessage();
        })
    }, [])

    return (
        <div className={styles.container}>
            {
                !showValidResult &&
                // false &&
                <ValidatorMain
                    setDocFn={setDocFn}
                    clearDocFn={clearDocFn}
                    loading={loading}
                    failReason={failReason}
                    setFailReasonFn={setFailReason}
                />
            }
            {
                showValidResult &&
                // true &&
                <ValidatorResult
                    validInfo={validInfo}
                    clearDocFn={clearDocFn}
                    setDocFn={setDocFn}
                    loading={loading}
                    failReason={failReason}
                    setFailReasonFn={setFailReason}
                />
            }
        </div>
    )
}