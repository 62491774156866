import {Alert, Button, Input, Modal, Space, Steps} from "antd";
import {InfoCircleFilled} from "@ant-design/icons";
import styles from './index.module.less'
import React, {useCallback, useEffect, useState} from "react";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import {doConvert, doUpload, downloadPdf, getDownloadUrl, getStatus} from "../../../../../../request/convert";
import {invalid2Online} from "../../../../Model";
import {getCCList, getSubscription} from "../../../../../../request/esign";
import IframeModal from "../../../../../components/IframeModal";
import {AUTH_PLUGIN_URI} from "../../../../../../App";
import {getStore} from "../../../../../../store";
import {hideMessage, loadingMessage} from "../../../../../components/Message";
import {inPhantom, isDarkMode} from "../../../../utils/util";

export default function Invalid({close, record}) {
    const {name, signRecipientList} = record;
    const [inputStatus, setInputStatus] = React.useState('');
    const [reasonStatus, setReasonStatus] = React.useState('');
    const [step, setStep] = React.useState(0);
    const [title, setTitle] = React.useState(name + '-作废协议书');
    const [reason, setReason] = React.useState('');
    const [showDoc, setShowDoc] = React.useState(false);
    const [document, setDocument] = React.useState('');
    const [showLoading, setShowLoading] = React.useState(false);
    const [userInfo, setUserInfo] = React.useState(null);
    const [carbonCopies, setCarbonCopies] = React.useState([]);
    const [authUrl, setAuthUrl] = useState(null);

    const checkResult = useCallback(({processId}) => {
        const { userEmail, userMobile } = getStore('userInfo')
        const { email, phone } = signRecipientList[1];
        if (
            (userMobile && phone && phone === userMobile) ||
            (userEmail && email && userEmail.toLowerCase() === email.toLowerCase())
        ) {
            Modal.confirm({
                title: '签署作废协议书',
                icon: <InfoCircleFilled style={{color: 'rgb(24, 144, 255)'}} />,
                width: 500,
                content: '作废协议书签署流程发起成功，是否立即签署？',
                okText: "确认",
                cancelText: "取消",
                onOk() {
                    close({success: true, processId});
                },
                onCancel() {
                    close({success: true});
                },
            });
        } else {
            close({success: true});
        }
    }, [signRecipientList, close]);


    useEffect(() => {
        // 查询抄送人
        const processId = record.process_id;
        getCCList({processId}).then(ccList => {
            if (!Array.isArray(ccList) || ccList.length < 1) {
                return;
            }
            setCarbonCopies(ccList);
        });
    }, [record.process_id]);

    useEffect(() => {
        window.eSignCN_invalidSignFLowNotice = ({ success, processId }) => {
            if (success) {
                checkResult({processId});
            }
        }
    }, [checkResult]);

    const onCancel = () => {
        if (showLoading) return;
        close();
    }

    const onSave = (info) => {
        if (step === 1) {
            if (!info) {
                setAuthUrl(getAuthUrl(getStore('userInfo')));
                return;
            }
            if (showLoading) {
                return;
            }
            getSubscription({wardType:0, tenantId: info.enterpriseId}).then(result => {
                const { expreTime, docNumber, serverTime } = result;
                if (expreTime - serverTime < 1) {
                    Modal.warning({
                        title: '签署限制',
                        content: `您${info.enterpriseId ? '所在企业':''}的对外签署服务有效期至${dayjs(expreTime * 1000).format('YYYY-MM-DD HH:mm:ss')}，签署服务已到期。`,
                    });
                    return;
                }
                if (docNumber < 1) {
                    Modal.warning({
                        title: '签署限制',
                        content: `您${info.enterpriseId ? '所在企业':''}的对外签署服务有效期至${dayjs(expreTime * 1000).format('YYYY-MM-DD HH:mm:ss')}，文档签署额度不足。`,
                    });
                    return;
                }
                const msg = <div>签署流程发起后，不可再修改签署参与人及文档内容，是否确认发起？
                    （将消耗 <span style={{color: '#A236B2', fontWeight: '600'}}>1</span> 个文档签署额度，签署参与人不消耗额度）</div>;
                Modal.confirm({
                    title: '确认发起作废流程',
                    icon: <InfoCircleFilled style={{color: 'rgb(24, 144, 255)'}} />,
                    width: 500,
                    content: msg,
                    okText: "确认",
                    cancelText: "取消",
                    onOk() {
                        fn();
                    },
                    onCancel() {
                    },
                });

            });
            return;
        }
        setInputStatus('');
        setReasonStatus('');
        let res = true;
        if (!title || title.length > 50) {
            setInputStatus('error');
            res = false;
        }
        if (!reason || reason.length > 200) {
            setReasonStatus('error');
            res = false;
        }
        if (res) {
            setStep(1);
            const html = getHtmlContent({record, reason});
            setDocument(html);
        }

        function fn() {
            setShowLoading(true);
            loadingMessage('文档上传中，请勿关闭当前窗口…')
            let url = '';
            const file = new File([document], title + '.html', {
                type: "text/html",
            });
            doUpload({
                file, uploadCb: (percent) => {
                    loadingMessage(`文档上传中(${percent}%)，请勿关闭当前窗口…`)
                }
            }).then(docid => {
                const docList = [{docid, password: ""}];
                return doConvert({docList});
            }).then(res => {
                return getStatus({
                    taskId: res.taskid, cb: (percent) => {
                        loadingMessage(`文档转换中(${percent}%)，请勿关闭当前窗口…`)
                    }
                });
            }).then(res => {
                url = getDownloadUrl({docid: res.docid, filename: title + '.pdf'});
                return downloadPdf({
                    url, cb: (percent) => {
                        percent !== 100 ? loadingMessage(`文档下载中(${percent}%)，请勿关闭当前窗口…`) : hideMessage();
                    }
                });
            }).then(buffer => {
                // const blob = new Blob([buffer], {type: "application/pdf"});
                // const blobUrl = URL.createObjectURL(blob);
                // window.open(blobUrl);

                const deadline = 0;
                return invalid2Online({title, url, reason, record, userInfo: info, buffer, carbonCopies, deadline});
            }).then(({success, processId}) => {
                if (success) {
                    checkResult({processId});
                }
            }).finally(() => {
                setShowLoading(false);
            });
        }
    }

    const toPrev = () => {
        if (showLoading) return;
        setStep(0);
    }

    const changeTitle = (e) => {
        const value = e.target.value;
        setTitle(value);
        if (!value || value.length > 50) {
            setInputStatus('warning');
        } else {
            setInputStatus('');
        }
    }

    const changeReason = (e) => {
        const value = e.target.value;
        setReason(value);
        if (!value || value.length > 200) {
            setReasonStatus('warning');
        } else {
            setReasonStatus('');
        }
    }

    const closeAuth = (data) => {
        setAuthUrl(null);
        const {success, person, company} = data;
        const info = getStore('userInfo');
        if (success) {
            const userInfo = {
                userEmail: info.userEmail,
                userMobile: info.userMobile,
                enterpriseId: info.enterpriseId,
                person,
                org: company
            };
            setUserInfo(userInfo);
            onSave(userInfo);
        }
    }

    return <div><Modal open={true} width={680} title={'发起作废'} maskClosable={false} className={styles.container} centered={inPhantom()}
                    footer={<div style={{display: 'flex', justifyContent: 'space-between'}}>
                        {step === 1 ? <Button onClick={toPrev}>上一步</Button> : <div></div>}
                        <Space>
                            <Button loading={showLoading} type="primary"
                                    onClick={() => onSave(userInfo)}>{step === 0 ? '下一步' : '发起签署'}</Button>
                            <Button onClick={onCancel}>取消</Button>
                        </Space>
                    </div>}
                    onCancel={onCancel}>
        <Alert
            message="发起作废协议书签署流程"
            description="需要原各签署参与方签订作废协议书，方可作废原文档。"
            type="info"
            icon={<InfoCircleFilled style={{color: "#1890ff"}}/>}
            showIcon
        />
        <Steps
            current={step}
            style={{width: '70%', margin: '20px auto'}}
            items={[
                {
                    title: '填写信息',
                    icon: <div className={styles.step1}>1</div>
                },
                {
                    title: '发起签署',
                }
            ]}
        />
        {step === 1 && <div>
            <p>作废协议书</p>
            <div className={styles.doc}>
                <span>{title}.pdf</span>
                <Button className={styles.btnLink} type="link" onClick={() => setShowDoc(true)}>预览</Button>
            </div>
            <p>签署参与人</p>
            <ul className={styles.list}>
                {
                    signRecipientList.filter((item, index) => index > 0).map(item => {
                        return <li key={item.id}>
                            <p>{item.name}（{item.company ? item.company : '个人'}）</p>
                            <p>{item.email || item.phone}</p>
                        </li>
                    })
                }
            </ul>
        </div>}
        {step === 0 && <>
            <div className={styles.inputContainer}>
                <p>任务名称</p>
                <Input value={title} placeholder="请输入作废流程名称"
                       showCount status={inputStatus} onChange={changeTitle} maxLength={50} allowClear/>
                {inputStatus === 'error' && <span className={styles.error}>请输入流程名称</span>}
            </div>
            <div className={styles.inputContainer}>
                <p>作废原因</p>
                <TextArea value={reason} placeholder="请输入作废原因，将填入到作废协议书中"
                          autoSize={{minRows: 6, maxRows: 6}}
                          allowClear showCount status={reasonStatus} maxLength={200} onChange={changeReason}/>
                {reasonStatus === 'error' && <span className={styles.error}>请输入作废原因</span>}
            </div>
        </>}
    </Modal>
        {showDoc && <HtmlDocument list={record.signRecipientList} document={document} title={title}
                                  close={() => setShowDoc(false)}/>}
        {authUrl && <IframeModal url={authUrl} closeFn={closeAuth} isSealPlugin={false}/>}
    </div>
}


function getHtmlContent({record, reason}) {
    let {name, signRecipientList, signed_hash, update_time} = record;
    const list = signRecipientList.filter((item, index) => index > 0);
    let inner = `<div style="font-family: '宋体'; font-size: 14px; line-height: 30px; margin: 24px 24px 0"><style>p{margin: 0 0 20px 0}</style>
        <p style="text-align: center; font-size: 18px; font-weight: 600">作废协议书</p>`;
    list.forEach((item, index) => {
        inner += `<p>参与方${index + 1}：
            <span style='text-decoration: underline'>${item.name}${item.company ? `（${item.company}）` : ''}</span>
        </p>`
    });
    inner += `<p style='margin-top: 30px'>各签署参与方于<span style='text-decoration: underline'>${dayjs(parseInt(update_time) * 1000).format('YYYY年MM月DD日')}</span>
        签署完成流程名称为《<span style='text-decoration: underline'>${name}</span>》的签署流程。
        现因<span style='text-decoration: underline'>${reason}</span>，经协商一致决定作废并解除签署文档的法律效力，此作废协议书自各方加盖合法有效电子签章后立即生效。</p>
        <p style="margin-top: 30px">签署文档Hash值：</p>
        <p><span style="text-decoration: underline">${signed_hash}</span></p>
        <p>附注：Hash值通过原文档全文（原文+已有签名）进行SHA256摘要算法后转换成的十六进制形式显示。</p>
       </div>`;
    return inner;
}

function HtmlDocument({title, close, document, list}) {
    return <Modal open={true} width={794} title={title + '.pdf'} footer={null}
                  maskStyle={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
                  style={{top: 30}}
                  bodyStyle={{padding: '36px'}}
                  onCancel={close}
    >
        <div className={ inPhantom() ? styles.docPreviewPhantom : styles.docPreview}>
            <div dangerouslySetInnerHTML={{__html: document}}></div>
            <div style={{display: 'flex', flexWrap: 'wrap', margin: '0 24px', fontSize: '16px'}}>
                {
                    Array.isArray(list) && list.filter((item, index) => index > 0).map((item, index) => {
                        return (
                            <div style={{
                                display: 'flex',
                                marginTop: '10px',
                                width: '50%',
                                height: '60px',
                                alignItems: 'center'
                            }} key={item.id}>参与方{index + 1}（签名）：
                            </div>
                        )
                    })
                }
            </div>
        </div>
    </Modal>
}

function getAuthUrl(info) {
    const token = info.token || '';
    const enterpriseId = info.enterpriseId || '';
    if (!token) {
        return '';
    }
    const url = AUTH_PLUGIN_URI + `?from=invalid&type=1&token=${token}&isOut=1&enterpriseId=${enterpriseId}`;
    return isDarkMode() ? url + '&theme=dark' : url;
}