import OutSignConfigOrg from "../pages/PublicPage/outSignConfigOrg";
import CertPlugin from "../pages/PublicPage/certPlugin";
import PhantomStampManage from "../pages/PublicPage/PhantomStampManage";
import CreateSeal from "../pages/main/components/createInnerSeals/CreateSeal";
import {closeESignCNDialogAPI} from "../request/phantom";
import AddOutSignStamp from "../pages/PublicPage/addOutSignStamp";
import IframeDialog from "../pages/PublicPage/addOutSignStamp/IframeDialog";
import PhantomESignAgree from "../pages/PublicPage/PhantomESignAgree";
import ESignCNOutStampApplyDialog from "../pages/PublicPage/stampApplyDialog";
import PhantomWillingnessAuth from "../pages/PublicPage/PhantomWillingnessAuth";
import {AuditTrail} from "../pages/PublicPage/auditTrail";
import EsignTools from "../pages/PublicPage/esignTools/EsignTools";
import PhantomReplacePhone from "../pages/PublicPage/replacePhone";
import PhantomInitFlowPanel from "../pages/PublicPage/PhantomInitFlowPanel";
import PhantomInitFlowPanelV2 from "../pages/PublicPage/PhantomInitFlowPanel/v2";
import PhantomSignFlowPanel from "../pages/PublicPage/PhantomSignFlowPanel";
import PhantomAddMobile from "../pages/PublicPage/PhantomAddMobile";
import WillingnessResultForPhantom from "../pages/PublicPage/PhantomWillingnessAuth/willingnessResult";
import PhantomAuth from "../pages/PublicPage/PhantomAuth";
import PhantomSignFlowPanelV2 from "../pages/PublicPage/PhantomSignFlowPanelV2";
import SealUsageRecord from "../pages/PublicPage/SealUsageRecord";
import PhantomStampDropDownV3 from "../pages/PublicPage/PhantomStampDropDown/v3";
import PhantomStampDropDownV2 from "../pages/PublicPage/PhantomStampDropDown/v2";
import PhantomStampDropDown from "../pages/PublicPage/PhantomStampDropDown/v1";
import PhantomInitFlowPanelV3 from "../pages/PublicPage/PhantomInitFlowPanel/v3";
import PhantomAuthPromotion from "../pages/PublicPage/PhantomAuthPromotion";
import {DeadlineDialog} from "../pages/PublicPage/PhantomInitFlowPanel/v3/DeadlineDialog";
import CCDialog from "../pages/PublicPage/PhantomInitFlowPanel/v3/CCDialog";
import {AUDIT_TRAIL_PATH} from "../pages/PublicPage/constant";
import PhantomAccount from "../pages/PublicPage/Account/phantomAccount";
import PublicSeal from "../pages/PublicPage/PhantomStamp/v1/publicSeal";
import SignRecordGroup from "../pages/PublicPage/SignRecordGroup";
import CommonSignValidator from "../pages/PublicPage/CommonSignValidator";
import Validator from "../pages/PublicPage/CommonSignValidator/demo";
import PhantomOrgAdmin from "../pages/PublicPage/OrgAdmin";
import PhantomStamp from "../pages/PublicPage/PhantomStamp/v1";
import PhantomStampV2 from "../pages/PublicPage/PhantomStamp/v2";
import PublicSealV2 from "../pages/PublicPage/PhantomStamp/v2/publicSeal";
import EsignNps from "../pages/PublicPage/nps";

const pluginRoutes = [
    {
        path: '/config-out-sign-org-info',
        name: 'plugins',
        exact: true,
        element: <OutSignConfigOrg/>,
    },
    {
        path: '/cert-plugin',
        name: 'cert-plugins',
        exact: true,
        element: <CertPlugin/>
    },
    {
        path: '/stamp-manage',
        name: 'stamp-manage',
        exact: true,
        element: <PhantomStampManage/>
    },
    {
        path: '/stamp-add',
        name: 'stamp-add',
        exact: true,
        element: <CreateSeal visible={true} closeAdd={closeESignCNDialogAPI}/>
    },
    {
        path: '/sign_record',
        name: 'sign_record',
        exact: true,
        element: <SignRecordGroup/>
    },
    {
        path: '/add-out-sign-stamp',
        name: 'add-out-sign-stamp',
        exact: true,
        element: <AddOutSignStamp/>
    },
    {
        path: '/iframe-dialog',
        name: 'iframe-dialog',
        exact: true,
        element: <IframeDialog/>
    },
    {
        path: '/eSignCN-cert',
        name: 'eSignCN-cert',
        exact: true,
        element: <PhantomESignAgree/>
    },
    {
        path: '/stamp-apply',
        name: 'stamp-apply',
        exact: true,
        element: <ESignCNOutStampApplyDialog/>
    },
    {
        path: '/willing-auth',
        name: 'willing-auth',
        exact: true,
        element: <PhantomWillingnessAuth/>
    },
    {
        path: AUDIT_TRAIL_PATH,
        name: 'signing-report',
        exact: true,
        element: <AuditTrail/>
    },
    {
        path: '/internal-identity-verify',
        name: 'internal-identity-verify',
        exact: true,
        element: <EsignTools/>
    },
    {
        path: '/replace-phone',
        name: 'replace-phone',
        exact: true,
        element: <PhantomReplacePhone/>
    },
    {
        path: '/init-flow',
        name: 'init-flow',
        exact: true,
        element: <PhantomInitFlowPanel/>
    },
    {
        path: '/init-flow-v2',
        name: 'init-flow-v2',
        exact: true,
        element: <PhantomInitFlowPanelV2/>
    },
    {
        path: '/init-flow-v3',
        name: 'init-flow-v3',
        exact: true,
        element: <PhantomInitFlowPanelV3/>
    },
    {
        path: '/sign-flow',
        name: 'sign-flow',
        exact: true,
        element: <PhantomSignFlowPanel/>
    },
    {
        path: '/sign-flow-v2',
        name: 'sign-flow-v2',
        exact: true,
        element: <PhantomSignFlowPanelV2/>
    },
    {
        path: '/stamp-dropdown',
        name: 'stamp-dropdown',
        exact: true,
        element: <PhantomStampDropDown/>
    },
    {
        path: '/stamp-dropdown-v2',
        name: 'stamp-dropdown-v2',
        exact: true,
        element: <PhantomStampDropDownV2/>
    },
    {
        path: '/stamp-dropdown-v3',
        name: 'stamp-dropdown-v3',
        exact: true,
        element: <PhantomStampDropDownV3/>
    },
    {
        path: '/add-mobile-phantom',
        name: 'add-mobile-phantom',
        exact: true,
        element: <PhantomAddMobile/>
    },
    {
        path: '/add-email-phantom',
        name: 'add-email-phantom',
        exact: true,
        element: <PhantomAddMobile isEmail={true}/>
    },
    {
        path: '/willingness-result-for-phantom',
        name: 'willingness-result-for-phantom',
        exact: true,
        element: <WillingnessResultForPhantom/>
    },
    {
        path: '/auth-for-phantom',
        name: 'auth-for-phantom',
        exact: true,
        element: <PhantomAuth/>
    },
    {
        path: '/seal-usage-record',
        name: 'seal-usage-record',
        exact: true,
        element: <SealUsageRecord/>
    },
    {
        path: '/phantom-auth-promotion',
        name: 'phantom-auth-promotion',
        exact: true,
        element: <PhantomAuthPromotion/>
    },
    {
        path: '/set-deadline',
        name: 'set-deadline',
        exact: true,
        element: <DeadlineDialog/>
    },
    {
        path: '/set-cc-list',
        name: 'set-cc-list',
        exact: true,
        element: <CCDialog/>
    },
    {
        path: '/phantom-account',
        name: 'phantom-account',
        exact: true,
        element: <PhantomAccount />
    },
    {
        path: '/phantom-stamp',
        name: 'phantom-stamp',
        exact: true,
        element: <PhantomStamp />
    },
    {
        path: '/phantom-stamp-v2',
        name: 'phantom-stamp',
        exact: true,
        element: <PhantomStampV2 />
    },
    {
        path: '/public-seal',
        name: 'public-seal',
        exact: true,
        purePlugin: true, // 纯插件页面，不需要调用容器页面API进行通信;
        element: <PublicSeal />
    },
    {
        path: '/public-seal-v2',
        name: 'public-seal',
        exact: true,
        purePlugin: true, // 纯插件页面，不需要调用容器页面API进行通信;
        element: <PublicSealV2 />
    },
    {
        path: '/sign-validator',
        name: 'sign-validator',
        exact: true,
        purePlugin: true, // 纯插件页面，不需要调用容器页面API进行通信;
        element: <CommonSignValidator />
    },
    {
        path: '/validator',
        name: 'validator',
        exact: true,
        purePlugin: true, // 纯插件页面，不需要调用容器页面API进行通信;
        element: <Validator/>
    },
    {
        path: '/phantom-org-admin',
        name: 'phantom-org-admin',
        exact: true,
        element: <PhantomOrgAdmin/>
    },
    {
        path: '/phantom-nps',
        name: 'phantom-nps',
        exact: true,
        element: <EsignNps/>
    }
]

export default pluginRoutes;