import {useState, useEffect} from "react";
import CreateTabs from "./CreateTabs";
import {Button, Checkbox, Modal} from "antd";
import styles from "../StampManage.module.less";
import ImageCropper from "./ImageCropper";
import {getCropBase64} from "../../../../utils";
import {getStore} from "../../../../store";
import {innerSealCreate} from "../../../../request/esign";
import {errorMessage, successMessage} from "../../../components/Message";
import {mockSwitch} from "../../../PublicPage/config";
import cuid from "cuid";

export default function CreateSeal(props) {
    const [isBlack, setIsBlack] = useState(true);
    const [hideBlack, setHideBlack] = useState(true);
    const [imgData, setImgData] = useState('');
    const [imgSize, setImgSize] = useState([0, 0]);
    const [showCropper, setShowCropper] = useState(false);
    const [cropData, setCropData] = useState('');
    const [btnLoading, setBtnLoading] = useState(false);
    const [useCrop, setUseCrop] = useState(false);

    useEffect(() => {
        if (window.location.pathname === '/stamp-add') {
            document.body.setAttribute('name', 'stamp-add-container')
        }
    }, [])

    const cancelAdd = () => {
        props.closeAdd(null);
    };

    const handleOk = () => {
        if (showCropper) {
            if (cropData.length > 100) saveData(cropData);
        } else if(!useCrop) {
            if (imgData.length > 100) saveData(imgData);
        } else {
            getCropBase64(imgData).then(data => {
                if (data.length > 100) saveData(data)
            });
        }
    }

    const setData = (data, useCrop = true, width = 0, height=0) => {
        if (!showCropper) {
            setImgData(data)
            setUseCrop(useCrop);
            setImgSize([width, height])
        }
    }

    const saveData = (saveData) => {
        const pictureBase64 = saveData.replace(/^data:.*?base64,/, '');
        if (props.isOutTemp) {
            props.closeAdd(saveData);
            return;
        }

        setBtnLoading(true);
        const signerId = cuid();
        innerSealCreate({signerId, pictureBase64, pictureHeight: imgSize[1], pictureWidth: imgSize[0]}).then(id => {
            if (!id) {
                errorMessage('新增印章失败！');
                return;
            }
            if (!getStore('plugin').inPhantom || mockSwitch) {
                successMessage('新增印章成功！');
            }

            props.closeAdd({id, base64: saveData});
        });
    }

    const className = getStore('plugin').inPhantom ? 'phantom-stamp-add' : ''
    const title = props.isOutTemp ? <span>添加临时签章 <span style={{color: '#666', fontSize: '14px'}}>（创建的签章仅在本次签署中可用，离开网页将丢失）</span></span> : '添加签章'
    return (
        <Modal title={title} open={true} onCancel={cancelAdd} footer={null} keyboard={false}
               centered={true} maskClosable={false} width={1000} bodyStyle={{padding: '0', margin: 0}} className={className}
        >
            <CreateTabs visible={!showCropper} isOutTemp={props.isOutTemp}
                        hideBlack={setHideBlack} isBlack={isBlack} setImgData={setData} />
            {
                showCropper && <ImageCropper base64={imgData}  setCropData={setCropData} />
            }
            <div className={styles.tabFooter}>
                <div>
                    {!hideBlack && !showCropper && <Checkbox checked={isBlack} onChange={e => setIsBlack(e.target.checked)}>
                        将图片转换为黑白色</Checkbox>
                    }
                </div>
                <div>
                    <Button disabled={!imgData} shape={"round"} onClick={() => setShowCropper(!showCropper)}>
                        {showCropper ? '上一步' : '裁切签名'}
                    </Button>
                    <Button disabled={!imgData} shape={"round"} type={"primary"} loading={btnLoading} onClick={handleOk}>确认</Button>
                    <Button shape={"round"} onClick={cancelAdd}>取消</Button>
                </div>
            </div>
        </Modal>
    )
}
