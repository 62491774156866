import {
    eSignCNAuditTrailContainer,
    eSignCNAuditTrailContainerHeaderSvg, eSignCNAuditTrailContainerHeaderTitle,
    eSignCNAuditTrailSection, eSignCNAuditTrailSectionFirstDiv,
    eSignCNAuditTrailWrapper, flex1, flex2, genAuditTrailContainerHeader, getMainStylesFn,
    padding10$0
} from "../style";
import {Button} from "antd";
import {Content1Comp} from "./Content1Comp";
import {Content2Comp} from "./Content2Comp";
import {Content3Comp} from "./Content3Comp";
import {Content4Comp} from "./Content4Comp";
import PdfViewerContainer from "../../PdfViewerContainer";
import {AUDIT_TYPE} from "../services";
import {Icon1, Icon2, Icon3, Icon4, Icon5, Logo} from "../static";
import {afterPhantomPluginVersion, inOnline} from "../../utils/util";
import {useTranslation} from "react-i18next";

export function AuditTrailMain(props) {

    const { t } = useTranslation('auditTrail');
    const { type = AUDIT_TYPE.WEB, qrCode, auditTrail, auditTrailMsg, file, getPdfDocInfoFn, handleDownloadAuditFile } = props;

    const showDownLoadBtn = (type) => {
        return type === AUDIT_TYPE.WEB && (inOnline() || afterPhantomPluginVersion([2024, 2, 0]));
    }
    return (
        <div style={{ ...eSignCNAuditTrailContainer, backgroundColor: type === AUDIT_TYPE.WEB ? '#EBEBEB' : '#FFF' }}>
            <div style={{ display: 'inline-block', textAlign: 'left' }}>
                <header style={genAuditTrailContainerHeader(type)}>
                    <Logo style={eSignCNAuditTrailContainerHeaderSvg}/>
                    {/*<span style={eSignCNAuditTrailContainerHeaderTitle}>签署报告</span>*/}
                    <span style={eSignCNAuditTrailContainerHeaderTitle}>{t('title')}</span>
                    <span>
                {
                    showDownLoadBtn(type) &&
                    // <Button style={{float: 'right', marginTop: '8px'}} onClick={handleDownloadAuditFile}>下载签署报告</Button>
                    <Button style={{float: 'right', marginTop: '8px'}} onClick={handleDownloadAuditFile}>{t('download')}</Button>
                }
            </span>
                </header>
                <section style={getMainStylesFn(type)}>
                    {
                        (!auditTrail || Object.keys(auditTrail).length < 1) &&
                        <div className='eSignCN-AuditTrail_no-data-area'>
                            {t('common:noData')}
                        </div>
                    }
                    {
                        auditTrail && Object.keys(auditTrail).length > 0 &&
                        <section>
                            <div style={eSignCNAuditTrailSection}>
                                <div style={flex1}>
                                    <div style={eSignCNAuditTrailSectionFirstDiv}>
                                        <Icon1/>
                                        {t('conclusion')}
                                    </div>
                                    {
                                        qrCode &&
                                        <div style={{ ...flex2, marginBottom: '12px' }}>
                                            <img style={{ width: '88px'}} src={qrCode} alt='qr_code'/>
                                            {/*扫码查看文档*/}
                                            {t('scanCode')}
                                        </div>
                                    }
                                </div>
                                <section style={eSignCNAuditTrailWrapper}>
                                    <ul style={{ paddingLeft: '18px' }}>
                                        { auditTrailMsg && auditTrailMsg.conclusion.tips.map(tip => <li style={padding10$0} key={Math.random()+Date.now()}>{tip}</li>) }
                                    </ul>
                                    {/*<div style={{ fontWeight: 700 }}>说明：</div>*/}
                                    <div style={{ fontWeight: 700 }}>{t('instruction')}</div>
                                    <div style={padding10$0}>{t('instruction1')}</div>
                                    <div style={padding10$0}>{t('instruction2')}</div>
                                </section>
                            </div>
                            <div style={eSignCNAuditTrailSection}>
                                <div style={eSignCNAuditTrailSectionFirstDiv}>
                                    <Icon2/>
                                    {/*文档签署基本信息*/}
                                    {t('basicInfo')}
                                </div>
                                <Content1Comp auditTrail={auditTrail}></Content1Comp>
                            </div>
                            <div style={eSignCNAuditTrailSection}>
                                <div style={eSignCNAuditTrailSectionFirstDiv}>
                                    <Icon3/>
                                    {/*签署方身份认证信息*/}
                                    {t('signerInfo')}
                                </div>
                                <Content2Comp auditTrail={auditTrail} type={type}></Content2Comp>
                            </div>

                            <div style={eSignCNAuditTrailSection}>
                                <div style={eSignCNAuditTrailSectionFirstDiv}>
                                    <Icon4/>
                                    {/*签署方证书信息*/}
                                    {t('signerCertInfo')}
                                </div>
                                <Content3Comp auditTrail={auditTrail}></Content3Comp>
                            </div>

                            <div>
                                <div style={eSignCNAuditTrailSectionFirstDiv}>
                                    <Icon5/>
                                    {/*文档签署过程信息*/}
                                    {t('signProcessInfo')}
                                </div>
                                <Content4Comp
                                    auditTrail={auditTrail}>
                                </Content4Comp>
                            </div>
                        </section>
                    }
                    {
                        type === AUDIT_TYPE.WEB &&
                        <PdfViewerContainer file={file} getPdfDocInfoFn={getPdfDocInfoFn}/>
                    }
                </section>
            </div>
        </div>
    )
}