import styles from "./Cert.module.less";
import icon from "../../../../assets/images/cert.png";
import {Button} from "antd";
import {useEffect, useState} from "react";
import {Logout} from "../../../components/Logout";
import {useTranslation} from "react-i18next";

export default function Cert(props) {
    const [info, setInfo] = useState({});

    const { t } = useTranslation('certPlugin');

    useEffect(() => {
        const changeInfo = (state) => {
            let data;
            switch (state) {
                case -1:
                    data = {
                        title: t('cert.companyNotRegistered'),
                        summary: t('cert.companyNotRegisteredInstruction'),
                        state
                    };
                    break;
                case 2:
                    data = {
                        title: t('cert.companyNotCertified'),
                        summary: t('cert.companyNotCertifiedInstruction'),
                        state
                    };
                    break;
                default:
                    data = {
                        title: t('cert.individualNotCertified'),
                        summary: t('cert.individualNotCertifiedInstruction'),
                        state
                    };
                    break;
            }
            setInfo(data);
        }
        const init = () => {
            if (props.orgName) {
                changeInfo(2);
            } else {
                changeInfo(1);
            }

        }
        init();
    }, [props]);


    return info.title && (
        <div className={styles.main}>
            <img src={icon} alt={"icon"} width="168" />
            <div className={styles.title}>{info.title}</div>
            <div className={styles.tips}>
                {info.summary}
            </div>
            <Button onClick={()=>props.changeState(info.state)} shape={"round"} type={"primary"} style={{width: '80%'}}>
                {info.state === -1 ? t('cert.contactSale') : t('cert.auth')}</Button>
            {info.state === -1 &&
                <Button onClick={()=>Logout()} shape={"round"} style={{width: '80%', marginTop: '20px'}}>{t('cert.logout')}</Button>}
        </div>
    )
}
