import styles from "./index.module.less";
import UploadFile from "./uploadFile";
import {ConfigProvider, Image} from "antd";
import zhCN from "antd/es/locale/zh_CN";
import validatorBg from "@/assets/images/phantom/validator-bg.png";
import {useTranslation} from "react-i18next";

export default function ValidatorMain(props) {
    const { setDocFn, clearDocFn, loading, failReason, setFailReasonFn} = props;

    const { t } = useTranslation('commonSignValidator');

    function setFailureReasonFn(reason) {
        setFailReasonFn(reason);
        clearDocFn()
    }

    return (
        <>
            <div className={styles.banner}>
                <div className={styles.content}>
                    <div className={styles.left}>
                        <div className={styles.title}>{t('foxitDocumentValidationService')}</div>
                        <div className={styles.desc}>
                            {t('validationServiceDescription')}
                        </div>
                        <div>
                            <UploadFile
                                setFailureReasonFn={setFailureReasonFn}
                                setDocFn={setDocFn}
                                loading={loading}
                            />
                            <ul className={styles.tips}>
                                <li>{t('supportedFormatsAndSize')}</li>
                                <li>{t('validationServiceLocalOnly')}</li>
                            </ul>
                            {
                                !!failReason &&
                                <div className={styles.error}>{failReason}</div>
                            }
                        </div>
                    </div>
                    <div className={styles.right}></div>
                </div>
            </div>
            <div className={styles.main}>
                <div className={styles.content}>
                    <div className={styles.title}>{t('otherValidationMethods')}</div>
                    <div className={styles.wrap}>
                        <div className={styles.left}>
                            <div className={styles.cycle1}>1</div>
                            <div className={styles.cycle2}>2</div>
                        </div>
                        <div className={styles.right}>
                            <div className={styles.subTitle}>{t('getFoxitAdvancedEditor')}</div>
                            <div>
                                <div>{t('installFoxitAdvancedEditorOnYourComputer')}</div>
                                <ul className={styles.desc}>
                                    <li>{t('winVersion')}<a className={styles.link} rel="noreferrer" href='https://www.foxitsoftware.cn/pdf-editor' target='_blank'>https://www.foxitsoftware.cn/pdf-editor</a></li>
                                    <li>{t('macVersion')}<a className={styles.link} rel="noreferrer" href='https://www.foxitsoftware.cn/pdf-editor-mac' target='_blank'>https://www.foxitsoftware.cn/pdf-editor-mac</a></li>
                                </ul>
                                <div>{t('orVisitWebVersion')}</div>
                                <ul className={styles.desc}>
                                    <li>{t('webVersion')}<a className={styles.link} rel="noreferrer" href='https://online.foxitsoftware.cn' target='_blank'>https://online.foxitsoftware.cn</a></li>
                                </ul>
                            </div>
                            <div className={styles.subTitle}>{t('openDocument')}</div>
                        </div>
                    </div>
                    <div>{t('openDocumentWithEditor')}</div>
                    <ConfigProvider locale={zhCN}>
                        <Image
                            width={640}
                            src={validatorBg}
                        />
                    </ConfigProvider>
                </div>
            </div>
        </>
    )
}