import React from 'react';
import styles from './index.module.less';
import OutPanel from "./OutPanel";
import {getParams} from "../../utils";
import { useTranslation } from 'react-i18next';

export default function ChooseType({close}) {
    const { t } = useTranslation('sealPlugin');
    const [isOrg, setIsOrg] = React.useState(false);
    const [showPanel, setShowPanel] = React.useState(false);

    const queryParams = getParams() || {};
    const {token, accountId, orgId, enterpriseId, trueName} = queryParams;
    if (!token || !accountId) {
        close();
        return;
    }

    const getUrl = async (isOrg = false) => {
        if (isOrg) {
            if (!enterpriseId || !orgId) {
                close(null, true);
                return;
            }
        }
        setIsOrg(isOrg);
        setShowPanel(true);
    };

    return (<>
            {!showPanel && <div className={styles.chooseType}>
                <div className={styles.title}>{t('title.chooseSealType')}</div>
                <div className={styles.content}>
                    <div className={styles.btn} onClick={() => getUrl()}>
                        <i className={styles.icon + ' ' + styles.person}></i>
                        <p className={styles.text}>{t('title.personalSeal')}</p>
                    </div>
                    <div className={styles.btn} onClick={() => getUrl(true)}>
                        <i className={styles.icon + ' ' + styles.org}></i>
                        <p className={styles.text}>{t('title.companySeal')}</p>
                    </div>
                </div>
            </div>}
            {showPanel && <OutPanel isCreated={isOrg ? 2 : 1} close={close} trueName={trueName} accountId={accountId}
                                    enterpriseId={enterpriseId} orgId={orgId} token={token}/>}
        </>

    );

}