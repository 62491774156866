import {isDarkMode, toFormateStr} from "../utils/util";

export const generateShareDocBeautyQrCode = async (qrCodeDataUri, data) => {
    const drawCanvas = (canvas, data, height = 500) => {
        let canvasWidth = 450;
        let canvasHeight = 500;
        return new Promise((resolve, reject) => {
            // 创建一个500*500的canvas, 背景色为#f1f1f1；将二维码qrCodeDataUri放在中间，大小为300*300；在画布的底部添加一个文字“请使用微信扫码”；将canvas转为base64；
            canvas.width = canvasWidth;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            let bgColor = isDarkMode() ? '#242424' : '#f1f1f1';
            let textColor = isDarkMode() ? '#fff' : '#333';
            ctx.fillStyle = bgColor;
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.fillRect(0, 0, canvasWidth, height);
            const img = new Image();
            img.src = qrCodeDataUri;
            img.onload = () => {
                ctx.drawImage(img, (canvasWidth - 300) / 2, (canvasHeight - 300) / 2, 300, 300);
                ctx.font = 'bold 16px Arial';
                ctx.fillStyle = textColor
                const text0 = `由${data.creator_name}分享的二维码`;
                const textWidth0 = ctx.measureText(text0).width;
                ctx.fillText(`${text0}`, (canvasWidth - textWidth0) / 2, 40);
                ctx.font = '14px Arial';
                const textWidth1 = ctx.measureText(data.taskName).width;
                // ctx.fillText(data.taskName, (500 - textWidth1) / 2, 80);
                toFormateStr(ctx, data.taskName, canvasWidth, -1, (canvasWidth - textWidth1) / 2 > 0 ? (canvasWidth - textWidth1) / 2 : 0, 70, 20);
                // 在另起一行 添加文字 “合同参与人”, 字体大小为12px, 颜色为#999
                ctx.font = '14px Arial';
                ctx.fillStyle = textColor
                const textWidth = ctx.measureText('请使用微信/支付宝扫码').width;
                toFormateStr(ctx, '请使用微信/支付宝扫码', canvasWidth, -1, (canvasWidth - textWidth) / 2 > 0 ? (canvasWidth - textWidth) / 2 : 0, 430, 20);
                // ctx.fillText('请使用微信/支付宝扫码', 180, 430);
                const text2 = `合同参与人：${data.receiptsNames}`;
                const textWidth2 = ctx.measureText(text2).width;
                const { liner, steps } = toFormateStr(ctx, text2, canvasWidth, -1, (canvasWidth - textWidth2) / 2 > 0 ? (canvasWidth - textWidth2) / 2 : 30, 460, 20);
                const text2Height = liner * 14 + (liner-1)*steps;
                const realHeight = text2Height + 460
                const base64 = canvas.toDataURL('image/png');
                if (realHeight > height) {
                    resolve(drawCanvas(canvas, data, realHeight));
                } else {
                    resolve(base64);
                }
            }
        })
    }
    const canvas = document.createElement('canvas');
    return drawCanvas(canvas, data);
}