import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './en-US.json';
import zh from './zh-CN.json';
import authPluginEn from '../pages/AuthPlugin/locales/en-US.json';
import authPluginZh from '../pages/AuthPlugin/locales/zh-CN.json';
import willAuthPluginEn from '../pages/WillAuthPlugin/locales/en-US.json';
import willAuthPluginZh from '../pages/WillAuthPlugin/locales/zh-CN.json';
import sealPluginEn from '../pages/SealPlugin/locales/en-US.json';
import sealPluginZh from '../pages/SealPlugin/locales/zh-CN.json';

export const resources = {
    en: {
        ...en,
        authPlugin: authPluginEn,
        willAuthPlugin: willAuthPluginEn,
        sealPlugin: sealPluginEn
    },
    zh: {
        ...zh,
        authPlugin: authPluginZh,
        willAuthPlugin: willAuthPluginZh,
        sealPlugin: sealPluginZh
    }
}

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        fallbackLng: 'zh',
        lng: 'zh',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;
