import {Button, Space} from "antd";
import {CheckOutlined, ClearOutlined, RedoOutlined, UndoOutlined} from "@ant-design/icons";
import {useEffect, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import {clearImage, drawCurve, getMiddlePoint, getPoint, initCanvas} from "./CreateDraw";
import styles from "./createDraw4Mobile.module.less";
import {qrDrawSave, qrDrawUpdate} from "../../../request/esign";
import {getParams} from "../../../utils";
import {ERROR_ESIGN_QRDRAW_TIMEOUT, ERROR_ESIGN_QRDRAW_USED} from "../../../request/error_container";

let isDown = false;
let startPoint = null;
let points = [];

export default function CreateDraw4Mobile() {
    const { t } = useTranslation("sealPlugin");
    const [status, setStatus] = useState(0);
    const [colorType, setColorType] = useState(0);
    const [isDrawing, setIdDrawing] = useState(false);
    const [lines, setLines] = useState([]);
    const [isLandscape, setIsLandscape] = useState(false);
    const [undoLines, setUndoLines] = useState([]);
    const [loading, setLoading] = useState(false);
    const canvasRef = useRef();
    const ctxRef = useRef();
    const canvasContainerRef = useRef();

    useEffect(() => {
        const orientationchange = () => {
            setTimeout(() => {
                const width = document.documentElement.clientWidth;
                const height = document.documentElement.clientHeight;
                const container = document.querySelector('.' + styles.container);
                const drawContainer = document.querySelector('.' + styles.drawContainer);
                const opts = document.querySelector('.' + styles.opts);
                if (!container) return;
                if (height > width) {
                    container.style.width = `${height}px`;
                    container.style.height = `${width}px`;
                    container.style.left = `${(width - height) / 2}px`;
                    container.style.top = `${(height - width) / 2}px`;
                    container.style.transform = 'rotate(90deg)';

                    drawContainer.style.width = `${height}px`;
                    drawContainer.style.height = `calc(${width}px - 3rem)`;

                    opts.style.width = `${height}px`;
                    opts.style.height = `3rem`;
                    setIsLandscape(true);
                } else {
                    container.style.width = `${width}px`;
                    container.style.height = `${height}px`;
                    container.style.left = `0px`;
                    container.style.top = `0px`;
                    container.style.transform = 'rotate(0deg)';

                    drawContainer.style.width = `${width}px`;
                    drawContainer.style.height = `calc(${height}px - 3rem)`;

                    opts.style.width = `${width}px`;
                    opts.style.height = `3rem`;
                    setIsLandscape(false);
                }
                if (!ctxRef.current) {
                    ctxRef.current = initCanvas(canvasContainerRef.current, canvasRef.current, 6, 'black');
                }
            }, 100);

        }

        const {token, id} = getParams();
        qrDrawUpdate({id, token}).then(() => {
            orientationchange();
            window.addEventListener('orientationchange', orientationchange);
            setStatus(1);
        }).catch(err => {
            if (ERROR_ESIGN_QRDRAW_TIMEOUT === err) {
                setStatus(-1);
            } else if (ERROR_ESIGN_QRDRAW_USED === err) {
                setStatus(-2);
            } else {
                setStatus(-3);
            }
        });

        document.body.style.overflow = 'hidden';
        document.body.style.touchAction = 'none';

        return () => {
            window.removeEventListener('orientationchange', orientationchange);
            document.body.style.overflow = 'unset';
            document.html.style.touchAction = 'unset';
        }
    }, []);

    const clear = () => {
        clearImage(ctxRef.current, canvasRef.current);
        setLines([]);
        setUndoLines([]);
    }

    const changeColor = (colorType) => {
        setColorType(colorType);
        ctxRef.current.strokeStyle = colorType > 0 ? 'red' : 'black';
        clearImage(ctxRef.current, canvasRef.current);
        lines.forEach(line => {
            if (line.length < 3) {
                return;
            }
            startPoint = line[0];
            for (let i = 1; i < line.length; i += 2) {
                const controlPoint = line[i];
                let endPoint = line[i + 1];
                if (line[i + 3] === undefined) {
                    if (line[i + 2]) {
                        endPoint = line[i + 2];
                        i++;
                    }
                }
                const middlePoint = getMiddlePoint(controlPoint, endPoint);
                drawCurve(ctxRef.current, startPoint, controlPoint, endPoint);
                startPoint = middlePoint;
            }
        });
    }

    const undo = () => {
        const temp = [...lines];
        const lastLine = temp.pop();
        if (!lastLine) {
            return;
        }

        clearImage(ctxRef.current, canvasRef.current);
        temp.forEach(line => {
            if (line.length < 3) {
                return;
            }
            startPoint = line[0];
            for (let i = 1; i < line.length; i += 2) {
                const controlPoint = line[i];
                let endPoint = line[i + 1];
                if (line[i + 3] === undefined) {
                    if (line[i + 2]) {
                        endPoint = line[i + 2];
                        i++;
                    }
                }
                const middlePoint = getMiddlePoint(controlPoint, endPoint);
                drawCurve(ctxRef.current, startPoint, controlPoint, endPoint);
                startPoint = middlePoint;
            }
        });
        setUndoLines([...undoLines, lastLine]);
        setLines(temp);
    }

    const redo = () => {
        const temp = [...undoLines];
        const line = temp.pop();
        if (!line) {
            return;
        }
        startPoint = line[0];
        for (let i = 1; i < line.length; i += 2) {
            const controlPoint = line[i];
            let endPoint = line[i + 1];
            if (line[i + 3] === undefined) {
                if (line[i + 2]) {
                    endPoint = line[i + 2];
                    i++;
                }
            }
            const middlePoint = getMiddlePoint(controlPoint, endPoint);
            drawCurve(ctxRef.current, startPoint, controlPoint, endPoint);
            startPoint = middlePoint;
        }
        setLines([...lines, line]);
        setUndoLines(temp);
    }

    const handleStart = (e) => {
        isDown = true;
        startPoint = getPoint(e, canvasRef.current, isLandscape);
        points = [startPoint];
    };

    const handleMove = (e) => {
        if (!isDown) {
            return;
        }
        const point = getPoint(e, canvasRef.current, isLandscape);
        points.push(point);
        if (points.length > 3) {
            const [controlPoint, endPoint] = points.slice(-2);
            const middlePoint = getMiddlePoint(controlPoint, endPoint);
            drawCurve(ctxRef.current, startPoint, controlPoint, endPoint);
            startPoint = middlePoint;
            setIdDrawing(true);
        }
    };

    const handleUp = () => {
        if (points.length > 3) {
            setUndoLines([]);
            setLines([...lines, points]);
        }
        setIdDrawing(false);
        isDown = false;
        startPoint = null;
        points = [];
    };

    const onSave = () => {
        setLoading(true);
        const base64 = canvasRef.current.toDataURL();
        const {token, id} = getParams();
        qrDrawSave({token, id, imgData: base64}).then(() => {
            setStatus(2);
        }).catch(err => {
            if (ERROR_ESIGN_QRDRAW_TIMEOUT === err) {
                setStatus(-1);
            } else if (ERROR_ESIGN_QRDRAW_USED === err) {
                setStatus(-2);
            } else {
                setStatus(-3);
            }
        }).finally(() => setLoading(false));
    }

    return (<>
        {status < 0 && <div className={styles.result}>
            <div className={styles.iconContainer}>
                <div className={styles.iconFailed}></div>
                <div className={status === -2 ? styles.statusUsed: styles.statusFailed}></div>
            </div>
            <div style={{color: status === -2 ? '#FF7422' : '', fontWeight: '600', fontSize: '1.2rem'}}>
                {status === -2 ? t('message.qrCodeUsed') : t('message.qrCodeExpired')}
            </div>
            {status === -2 && <div style={{marginTop: '0.5rem'}}>
                {t('message.noRepeatScan')}
            </div>}
        </div>}
        {status === 2 && <div className={styles.result}>
            <div className={styles.iconContainer}>
                <div className={styles.iconSuccess}></div>
                <div className={styles.statusSuccess}></div>
            </div>
            <div style={{fontWeight: '600', fontSize: '1.2rem'}}>
                {t('message.drawingComplete')}
            </div>
            <div style={{marginTop: '0.5rem'}}>
                {t('message.backToPC')}
            </div>
        </div>}
        <div style={{display: status === 1 ? '' : 'none'}} className={styles.container}
             onContextMenu={e => e.preventDefault()}>
            <div ref={canvasContainerRef} className={styles.drawContainer}>
                {(lines.length < 1 && !isDrawing) && <div className={styles.tips}>{t('message.drawHere')}</div>}
                <canvas ref={canvasRef}
                        className={styles.canvas}
                        onTouchStart={handleStart}
                        onTouchMove={handleMove}
                        onTouchEnd={handleUp}
                ></canvas>
            </div>
            <div className={styles.opts}>
                <Space size={'middle'}>
                    <Button onClick={() => changeColor(0)} shape={'circle'} icon={<CheckOutlined/>}
                            style={{backgroundColor: 'black', color: colorType === 0 ? 'white' : 'black', borderRadius: 100}}></Button>
                    <Button onClick={() => changeColor(1)} shape={'circle'} icon={<CheckOutlined/>}
                            style={{backgroundColor: 'red', color: colorType === 1 ? 'white' : 'red', borderRadius: 100}}></Button>
                </Space>
                <div>
                    <Button disabled={lines.length < 1} onClick={undo} type={'text'}
                            icon={<UndoOutlined style={{fontSize: 16}}/>} className={styles.btn}>{t('button.undo')}</Button>
                    <Button disabled={undoLines.length < 1} onClick={redo} type={'text'}
                            icon={<RedoOutlined style={{fontSize: 16}}/>} className={styles.btn}>{t('button.redo')}</Button>
                    <Button disabled={lines.length < 1} onClick={clear} type={'text'}
                            icon={<ClearOutlined style={{fontSize: 16}}/>} className={styles.btn}>{t('button.clear')}</Button>
                    <Button disabled={lines.length < 1} loading={loading} type={'primary'}
                            className={styles.btn} onClick={onSave}>{t('button.complete')}</Button>
                </div>
            </div>
        </div>
    </>);
}