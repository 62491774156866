import {useState, useEffect} from "react";
import CreateTabs from "./CreateTabs";
import {Button, Space} from "antd";
import styles from "../index.module.less";
import ImageCropper from "./ImageCropper";
import {getCropBase64, getParams} from "../../../utils";
import {innerSealCreate} from "../../../request/esign";
import {errorMessage, successMessage} from "../../components/Message";
import cuid from "cuid";
import {sendMsg} from "../index";
import { useTranslation } from 'react-i18next';

export default function CreateSeal({close}) {
    const { t } = useTranslation('sealPlugin');
    const [imgData, setImgData] = useState('');
    const [imgSize, setImgSize] = useState([0, 0]);
    const [showCropper, setShowCropper] = useState(false);
    const [cropData, setCropData] = useState('');
    const [btnLoading, setBtnLoading] = useState(false);
    const [useCrop, setUseCrop] = useState(false);

    const queryParams = getParams() || {};
    let {token, enterpriseId, isTemp, isOut} = queryParams;
    isTemp = parseInt(isTemp) === 1;
    isOut = parseInt(isOut) === 1;

    useEffect(() => {
        const height = 440;
        sendMsg({type: 'resize', width: 1032, height});
    }, [isTemp])

    if (!token) {
        return;
    }
    if (!enterpriseId && !isTemp) {
        return;
    }

    const handleOk = () => {
        if (showCropper) {
            if (cropData.length > 100) saveData(cropData);
        } else if(!useCrop) {
            if (imgData.length > 100) saveData(imgData);
        } else {
            getCropBase64(imgData).then(data => {
                if (data.length > 100) saveData(data)
            });
        }
    }

    const setData = (data, useCrop = true, width = 0, height=0) => {
        if (!showCropper) {
            setImgData(data)
            setUseCrop(useCrop);
            setImgSize([width, height])
        }
    }

    const saveData = (saveData) => {
        const pictureBase64 = saveData.replace(/^data:.*?base64,/, '');
        if (isTemp && isOut) {
            close(saveData, false, imgSize[0], imgSize[1]);
            return;
        }

        setBtnLoading(true);
        const signerId = cuid();
        let scale = 1;
        let width = imgSize[0];
        let height = imgSize[1];
        if (width > 0 && height > 0) {
            scale = 1;
        } else if (width > 0) {
            scale = width;
            height = 0;
            width = 0;
        } else if (height > 0) {
            scale = height;
            height = 0;
            width = 0;
        }
        innerSealCreate({token, enterpriseId, signerId, pictureBase64, width, height, scale}).then(id => {
            if (!id) {
                errorMessage(t('message.addSealFailed'));
                return;
            }
            successMessage(t('message.addSealSuccess'));
            close({id, base64: saveData});
        });
    }

    return (
        <>
            <CreateTabs visible={!showCropper} isOutTemp={isTemp && isOut} setImgData={setData} />
            {
                showCropper && <ImageCropper base64={imgData}  setCropData={setCropData} setImgSize={setImgSize} />
            }
            <div className={styles.tabFooter}>
                <Space>
                    <Button disabled={!imgData}  onClick={() => setShowCropper(!showCropper)}>
                        {showCropper ? t('button.prevStep') : t('button.cropSignature')}
                    </Button>
                    <Button disabled={!imgData} type={"primary"} loading={btnLoading} onClick={handleOk}>{t('button.confirm')}</Button>
                    <Button onClick={() => close(null)}>{t('button.cancel')}</Button>
                </Space>
            </div>
        </>
    )
}
