import styles from "./Agree.module.less";
import icon from "../../../../assets/images/esignbao.png";
import {Button} from "antd";
import {useEffect, useState} from "react";
import {getAuthUrl} from "../../../../request/esign";
import {resizeDialog} from "../../Model";
import {Trans, useTranslation} from "react-i18next";

export default function Agree(props) {
    const [btnLoading, setBtnLading] = useState(false);

    const { t } = useTranslation('certPlugin');

    const authParams = props.authParams;
    const isOrgAuth = authParams?.orgName;
    const toEsignbao = () =>{
        setBtnLading(true);
        getAuthUrl(authParams).then(url => {
            if (url) {
                window.location.href = url;
            }
        });
    }

    useEffect(() => {
        resizeDialog({ width: 840, height: 500 });
    }, [])

    useEffect(() => {
        if (authParams.orgName) {
            getAuthUrl(authParams).then(url => {
                if (url) {
                    window.location.href = url;
                }
            });
        }
    }, [authParams])

    return (
        <div className={styles.main}>
            {!isOrgAuth &&
                <>
                    <div className={styles.head}>
                        <div className={styles.title}>
                            {t('agree.title')}
                        </div>
                        <img src={icon} alt={"icon"} width="303" height="58" />
                    </div>
                    <div className={styles.box}>
                        {t('agree.content1')}
                        <br/><br/>
                        {t('agree.content2')}
                        <br/><br/>
                        <Trans
                            i18nKey="certPlugin:agree.content3"
                            components={{
                                1: <a href="https://www.esign.cn/gw/fwxy/?current=1" rel="noreferrer" target='_blank'></a>
                            }}
                        />
                        {/*<br/><br/>*/}
                        {/*本电子签章服务目前处于测试阶段。测试版可能存在缺陷或不完善之处。因此，您同意您对测试版的使用仅用于测试和评估的目的，不得将测试版用于任何商业、交易、管理、行政、法律或其他正式的场合，或用于创设、变更、消灭法律权利、法律义务或法律身份。否则，您需要自行承担由此给您本人、机构和其他相关方造成的一切风险和损失。*/}
                        {/*<br/><br/>*/}
                        {/*对测试版的使用适用<a href="src/pages/cert/components/Agree" rel="noreferrer" target='_blank'>《福昕软件测试版附加协议》</a> （ “附加协议”），且附加协议与《福昕软件服务协议》、《福昕软件隐私策略》的内容不一致的，以附加协议为准。*/}

                    </div>
                    <Button loading={btnLoading} onClick={()=>toEsignbao()} shape={"round"} type={"primary"} style={{width: '80%'}}>{t('agree.btn')}</Button>
                </>
            }
            {
                isOrgAuth &&
                <div className={styles.loadingContainer}>
                    <div className={styles.loading}>
                        <div className={styles.loadingBg}>
                            <div className={styles.loadingIcon}></div>
                        </div>
                        <div className={styles.loadingText}>{t('agree.loading')}</div>
                    </div>
                </div>
            }
        </div>
    )
}
